<template>
  <div class="p-4 mt-6 border border-primary rounded-2xl shadow-primary-md">
    <div class="flex flex-wrap" :class="quotationDetailContainerWidth < 640 ? '' : 'justify-between'">
      <div class="flex items-center" :class="quotationDetailContainerWidth < 640 ? 'w-full mb-4' : 'w-max mb-0'">
        <h2 class="w-full text-base font-medium text-center text-gray">Is Negotiation Required?</h2>
      </div>
      <div :class="quotationDetailContainerWidth < 640 ? 'w-full' : 'w-max'">
        <div class="gap-3" :class="quotationDetailContainerWidth < 640 ? 'flex-col' : 'grid grid-cols-2'">
          <button-gista type="secondary" color="primary" size="small" :customClass="quotationDetailContainerWidth < 640 ? 'w-full mb-3' : 'w-24'" @click="changeNegotiationRequired(false)" :disabled="disabled">No</button-gista>
          <button-gista size="small" :customClass="quotationDetailContainerWidth < 640 ? 'w-full' : 'w-24'" @click="changeNegotiationRequired(true)" :disabled="disabled">Yes</button-gista>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'NegotiationRequired',
  props: ['negotiationRequired', 'disabled'],
  data() {
    return {}
  },
  computed: {
    quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
  },
  methods: {
    changeNegotiationRequired(val) {
      this.$emit('changeNegotiationRequired', val)
    },
  }
}
</script>