<template>
  <div>
    <ButtonGista :class="quotationDetailContainerWidth < 640 ? 'w-full' : 'w-max'" type="secondary" color="primary" @click="changeIsOpen(true)" :disabled="disabled">Save As Draft</ButtonGista>
    <ModalConfirm 
        title="Simpan sebagai Draft" 
        message="Anda dapat melanjutkan Revisi Penawaran Harga di detail negosiasi" 
        icon="saved" textButton="Save As Draft" 
        :isOpen="isOpenBanSaveAsDraft" 
        @changeIsOpen="changeIsOpen($event)" 
        @click="clickSaveAsDraft">
      </ModalConfirm>
  </div>
</template>

<script>
  import ModalConfirm from '@/core/components/modal/Confirm'
  export default {
    name: 'BanSaveAsDraft',
    props: ['isOpenBanSaveAsDraft', 'disabled'],
    computed: {
      quotationDetailContainerWidth() {
        return this.$store.state.width.quotationDetailContainerWidth
      },
    },
    methods: {
      clickSaveAsDraft() {
        console.log('send email');
        this.$emit('click')
       
      },
      changeIsOpen(val) {
        this.$emit('clickToggle', val)
      }
    },
    components: {
      ModalConfirm
    }
  }
</script>