<template>
  <div class="w-full">
    <div class="relative flex w-full my-6" :class="quotationDetailContainerWidth < 640 ? 'gap-2.5 flex-col items-start' : 'items-center '">
      <span class="text-2xl cursor-pointer icon-arrow-left text-gray" @click="() => isOpenCancel = true"></span>
      <ModalConfirm 
        title="Batalkan Perubahan?" 
        message="Perubahan tidak akan tersimpan" 
        icon="rejected" 
        textButton="Yes" 
        :isOpen="isOpenCancel" 
        @changeIsOpen="() => isOpenCancel = false" 
        @click="cancelLoi"
        colorButton="error"></ModalConfirm>
      <div class="flex justify-center w-full">
          <div>
            <h4 class="text-lg font-medium text-gray-darkest">EDIT LOI</h4>
          </div>
      </div>
    </div>
    <div>
      <Information @changeCollapse="changeCollapse" :collapse="collapse.information"/>
      <CardLoi />
      <Approval />
    </div>
    <div class="pt-6 mt-6 border-t-2 border-gray-lightest ">
      <div class="flex justify-end">
        <div class="flex" :class="quotationDetailContainerWidth > 640 ? '' : 'flex-col gap-6 w-full'">
          <SubmitLoi 
            title="Kirim Perubahan 'LOI'?"
            @click="submitLoi" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import SubmitLoi from '@/core/components/rfq/loi/SubmitLoi.vue'
import { MESSAGE_SUCCESS_CREATE_LOI, MESSAGE_SUCCESS_CANCEL_SKT_LOI } from '@/core/constant/successMessage'
import Information from '@/core/components/rfq/loi/Information.vue'
import Approval from '@/core/components/rfq/loi/Approval.vue'
import CardLoi from '@/core/components/rfq/loi/CardLoi.vue'
import ModalConfirm from '@/core/components/modal/Confirm'
import { MESSAGE_POPUP_VALIDATION_SUBMIT, MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'

export default {
  name: "EditLoi",
  data() {
    return {
      isOpenCancel: false,
      collapse: {
        information: false
      }
    }
  },
  computed:{
    quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
    draftLoi: {
      get() {
        return this.$store.state.loiRfq.draftLoi
      },
      set(value) {
        this.$store.commit('loiRfq/SET_DRAFT_SKT_LOI', value)
      }
    },
    approvals() {
      return this.$store.state.loiRfq.approvals
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    }
  },
  methods:{
    setErrorApprovals() {
      if (!this.approvals.APPROVER_DLOG?.search) {
        this.draftLoi.error['Approver ITP'] = MESSAGE_EMPTY_FIELD
      }
      if (!this.approvals.APPROVER_GSIT?.search) {
        this.draftLoi.error['Approver GSIT'] = MESSAGE_EMPTY_FIELD
      }
    },
    setErrorVendor() {
      if (!this.draftLoi.vendorPic) {
        this.draftLoi.error['PIC Vendor'] = MESSAGE_EMPTY_FIELD
      }
      if (!this.draftLoi.vendorEmail) {
        this.draftLoi.error['Email Vendor'] = MESSAGE_EMPTY_FIELD
      }
    },
    setError() {
      this.setErrorApprovals()
      this.setErrorVendor()
    },
    checkErrorSubmit() {
      return Object.keys(this.draftLoi.error).filter(e => this.draftLoi.error[e])
    },
    async cancelLoi() {
      this.$store.commit('sktLoi/SET_MAIN_PAGE', '')
			this.modal.modalSuccessSaved = {value: true, title: MESSAGE_SUCCESS_CANCEL_SKT_LOI }
      await this.$store.dispatch('loiRfq/getLoi', { rfqId: this.$route.params.quotationId })
    },
    changeCollapse(key) {
      this.collapse[key] = !this.collapse[key]
    },
    async submitLoi() {
      this.modal.modalLoading = true
      await this.setError()
      const checkErrorSubmit = await this.checkErrorSubmit()
      const messageError = checkErrorSubmit.join(', ')
      if (checkErrorSubmit.length) {
        this.modal.modalLoading = false
        this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT + ' pada field ' + messageError }
        return
      }
      const response = await this.$store.dispatch('loiRfq/updateSubmitLoi')
      if (response?.status < 300) {
        await this.$store.dispatch('projectLog/refetchLoiRfqLog', { rfqId: this.$route.params.quotationId })
        this.$store.commit('loiRfq/SET_MAIN_PAGE', '')
        this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_CREATE_LOI }
      }
      this.modal.modalLoading = false
    },
  },
  components:{
    SubmitLoi,
    Information,
    Approval,
    CardLoi,
    ModalConfirm
  }
}
</script>

<style scoped>
.min-w-custom {
	min-width: 20rem;
}
</style>