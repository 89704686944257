<template>
  <div class="w-full sm:w-max">
    <ButtonGista
      @click="changeIsOpen" 
      color="success"
      customClass="sm:w-32 w-full">
      Send RFQ</ButtonGista>
    <GistaModal 
      :value="isOpen"
			width="1/2"
			@input="closeIsOpen"
			customClass="bg-white max-w-2xl">
			<div class="px-6 py-6">
				<div class="flex justify-center">
					<h4 class="mb-6 text-xl font-semibold text-center text-gray">Kirim RFQ to Vendor</h4>
				</div>
				<div class="flex justify-center mb-6">
					<p class="mb-6 text-base font-medium text-center text-gray">Apakah Anda yakin?</p>
				</div>

				<div class="flex flex-col justify-center gap-3 sm:flex-row">
                    <ButtonGista @click="closeIsOpen"
                        type="secondary"
						color="gray"
						customClass="w-full sm:w-56 sm:mr-6">
						Back</ButtonGista>
					<ButtonGista @click="clickSendRfq"
						color="success"
						customClass="w-full sm:w-56">
						Send RFQ</ButtonGista>
				</div>
			</div>
		</GistaModal>
  </div>
</template>

<script>
  import { MESSAGE_SUCCESS_SEND_RFQ } from '@/core/constant/successMessage'
  export default {
    name: 'SendRFQ',
    props: ['isEnglish'],
    data() {
      return {
        isOpen: false
      }
    },
    computed: {
      modal: {
        get() {
          return this.$store.state.modal.modal
        },
        set(value) {
          this.$store.commit('modal/SET_MODAL', value)
        }
      },
      projectLogRfq() {
        return this.$store.state.projectLog.projectLogRfq
      },
    },
    methods: {
      changeIsOpen() {
        this.isOpen = true;
      },
      closeIsOpen() {
        this.isOpen = false;
      },
      refetchProjectLog() {
        this.$store.dispatch('projectLog/refetchProjectLogByRfqId', this.$route.params.quotationId)
      },
      async clickSendRfq() {
        this.isOpen = false;
        try {
          const payload = {
            rfqId: +this.$route.params.quotationId,
            isEnglish: this.isEnglish
          }
          this.modal.modalLoading = true
          const response = await this.$store.dispatch('quotationDetail/putSendRFQ', payload)
          if (response?.status < 300 && response?.status >= 200) {
            this.modal.modalLoading = false
            this.modal.modalSuccessSubmitted = { value: true, title: MESSAGE_SUCCESS_SEND_RFQ }
            this.$store.commit('projectLog/SET_RFQ_LOG_ID', this.projectLogRfq?.rfqLogs?.at(-1)?.rfqLogId)
            this.refetchProjectLog()
          } else {
            this.$store.dispatch('modal/throwError', { response })
          }
        } catch (error) {
          this.modal.modalLoading = false
          this.modal.modalError = { value: true, title: error.title, message: error.message, traceid: error.traceid }
        }
      },
    },
  }
</script>