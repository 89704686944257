<template>
  <div class="flex items-center">
    <gista-modal
      :value="isOpen"
      width="11/12"
			@input="toggle"
			customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-screen-2xl h-full">
      <div class="h-full">
        <div class="flex flex-col items-center justify-center gap-3 mb-5 sm:mb-0 sm:h-16 sm:flex-row">
          <p class="py-5 text-xl font-semibold text-gray">Negotiation Details</p>
          <div class="w-full sm:py-4 sm:absolute sm:w-max sm:right-14">
            <button-gista size="small" class="w-full" @click="toggleExport" v-if="menuRfqNegotiationBanReport?.canCreate" >
              Export to Excel
            </button-gista>
          </div>
        </div>
        <div class="overflow-x-auto height-custom">
          <table class="w-full border-collapse h-full" aria-describedby="maximize">
            <thead>
              <tr class="sticky top-0 z-50 h-10 bg-white">
                <th scope="col" class="lg:sticky left-0 z-40 h-10 p-0 text-sm bg-white"><div class="w-12 h-full border-b-2 border-primary"></div></th>
                <th scope="col" class="lg:sticky z-40 h-10 p-0 text-sm bg-white custom-left-2">
                  <h5 class="border-b-2 border-primary font-medium flex items-center justify-center p-4 h-10 w-12">
                    No
                  </h5>
                </th>
                <th scope="col" class="lg:sticky z-40 h-10 p-0 text-sm bg-white custom-left-3">
                  <h5 class="w-40 flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary">
                    ID Product
                  </h5>
                </th>
                <th scope="col" class="lg:sticky z-40 h-10 p-0 text-sm bg-white custom-left-4">
                  <h5 class="w-40 flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary">
                    Type
                  </h5>
                </th>
                <th scope="col" class="lg:sticky z-40 h-10 p-0 text-sm bg-white custom-left-5">
                  <h5 class="w-64 h-10 p-0 font-medium border-b-2 border-primary">
                    <span class="flex items-center justify-center w-full h-full p-4 border-r border-gray-lightest">
                      Product/Service
                    </span>
                  </h5>
                </th>
                <th scope="col" class="z-20 h-10 p-0 text-sm bg-white">
                  <h5 class="flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary min-w-64">
                    Sub Product
                  </h5>
                </th>
                <th scope="col" class="z-20 h-10 p-0 text-sm bg-white">
                  <h5 class="flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary min-w-64">
                    Description
                  </h5>
                </th>
                <th scope="col" class="z-20 h-10 p-0 text-sm bg-white ">
                  <h5 class="flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary min-w-40">
                    Location
                  </h5>
                </th>
                <th scope="col" class="z-20 h-10 p-0 text-sm bg-white ">
                  <h5 class="flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary min-w-40">
                    Qty
                  </h5>
                </th>
                <th scope="col" class="z-20 h-10 p-0 text-sm bg-white ">
                  <h5 class="flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary min-w-40">
                    UoM
                  </h5>
                </th>
                <th scope="col" class="z-20 h-10 p-0 text-sm bg-white ">
                  <h5 class="flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary min-w-40">
                    Period
                  </h5>
                </th>
                <th scope="col" class="z-20 h-10 p-0 text-sm bg-white ">
                  <h5 class="flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary min-w-24">
                    Currency
                  </h5>
                </th>
                <th scope="col" class="z-20 h-10 p-0 text-sm bg-white ">
                  <h5 class="flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary min-w-40">
                    Monthly Cost
                  </h5>
                </th>
                <th scope="col" class="z-20 h-10 p-0 text-sm bg-white ">
                  <h5 class="flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary min-w-40">
                    Unit Price
                  </h5>
                </th>
                <th scope="col" class="z-20 h-10 p-0 text-sm bg-white ">
                  <h5 class="flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary min-w-40">
                    Total Unit Price
                  </h5>
                </th>
                <th scope="col" class="z-20 h-10 p-0 text-sm bg-white ">
                  <h5 class="flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary min-w-24">
                    Disc %
                  </h5>
                </th> 
                <th scope="col" class="z-20 h-10 p-0 text-sm bg-white ">
                  <h5 class="flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary min-w-40">
                    Disc Price
                  </h5>
                </th>
                <th scope="col" class="z-20 h-10 p-0 text-sm bg-white ">
                  <h5 class="flex items-center justify-center h-10 p-4 font-medium border-b-2 border-primary min-w-40">
                    Final Total Price
                  </h5>
                </th>
              </tr>
            </thead>
            <tbody v-for="n,i in negotiationDetail?.offers" :key="i" class="h-full">
              <tr v-if="negotiationId ? n.negotiationId === negotiationId : true" class="sticky z-40 cursor-pointer top-10 bg-primary-light" @click="changeCollapse(i)">
                <td class="lg:sticky left-0 z-20 p-0" colspan="1" >
                  <div class="flex items-center justify-center  h-full">
                    <span v-if="!collapse[i]" class="text-base icon-minus text-gray"></span>
                    <span v-if="collapse[i]" class="text-base icon-plus text-gray"></span>
                  </div>
                </td>
                <td class="lg:sticky z-20 p-0 pr-8 h-10 custom-left-2" colspan="4">
                  <p class="text-sm font-medium text-gray-darkest">{{ negotiationId ? title : n.title }}</p>
                </td>
                <td colspan="13"></td>
              </tr>
              <template v-if="!collapse[i] && (negotiationId ? n.negotiationId === negotiationId : true)">
                <tr v-for="item,j in n.offerDetails" :key="j+'a'" class="h-full text-center" :class="j % 2 === 0 ? 'bg-white' : 'bg-primary-lightest bg-opacity-25'">
                  <td class="align-top lg:sticky left-0 z-20 p-0 h-full text-sm bg-white">
                    <div class="h-full" :class="j % 2 === 0 ? 'bg-white' : 'bg-primary-lightest bg-opacity-25'"></div>
                  </td>
                  <td class="align-top lg:sticky z-20 p-0 text-sm h-full custom-left-2 bg-white">
                    <div class="flex justify-start p-4 font-medium h-full" :class="j % 2 === 0 ? 'bg-white' : 'bg-primary-lightest bg-opacity-25'">
                      {{ item.no ?? '-' }}
                    </div>
                  </td>
                  <td class="align-top lg:sticky z-20 p-0 text-sm h-full custom-left-3 break-all  bg-white">
                    <div class="flex justify-start p-4 font-medium h-full" :class="j % 2 === 0 ? 'bg-white' : 'bg-primary-lightest bg-opacity-25'">
                      <p class="font-medium text-gray-darkest text-left">{{item.productId}}</p>
                    </div>
                  </td>
                  <td class="align-top lg:sticky z-20 p-0 text-sm h-full custom-left-4 break-all  bg-white">
                    <div class="flex justify-start p-4 font-medium h-full" :class="j % 2 === 0 ? 'bg-white' : 'bg-primary-lightest bg-opacity-25'">
                      <p class="font-medium text-gray-darkest text-left">{{item.jenis}}</p>
                    </div>
                  </td>
                  <td class="align-top h-full lg:sticky z-20 p-0 text-sm h-full custom-left-5 break-all bg-white">
                    <div class="flex h-full p-4 font-medium h-full border-r border-gray-lightest" :class="j % 2 === 0 ? 'bg-white' : 'bg-primary-lightest bg-opacity-25'">
                      <p class="font-medium text-gray-darkest text-left">{{item.productLayanan}}</p>
                    </div>
                  </td>
                  <td class="align-top p-0 text-sm">
                    <div class="flex justify-start p-4 font-medium">
                      <p class="font-medium text-gray-darkest text-left">{{item.subProduct}}</p>
                    </div>
                  </td>
                  <td class="align-top p-0 text-sm">
                    <div class="flex justify-start p-4 font-medium">
                      <p class="font-medium text-gray-darkest text-left">{{item.description}}</p>
                    </div>
                  </td>
                  <td class="align-top p-0 text-sm">
                    <div class="flex justify-start p-4 font-medium">
                      <p class="font-medium text-gray-darkest text-left">{{item.location}}</p>
                    </div>
                  </td>
                  <td class="align-top p-0 text-sm">
                    <div class="flex justify-start p-4 font-medium">
                      {{formatCurrency(item.qty, item.currency)}}
                    </div>
                  </td>
                  <td class="align-top p-0 text-sm">
                    <div class="flex justify-start p-4 font-medium">
                      <p class="font-medium text-gray-darkest text-left">{{item.uom}}</p>
                    </div>
                  </td>
                  <td class="align-top p-0 text-sm">
                    <div class="flex justify-start p-4 font-medium">
                      <p class="font-medium text-gray-darkest text-left">{{item.period}}</p>
                    </div>
                  </td>
                  <td class="align-top p-0 text-sm">
                    <div class="flex justify-start p-4 font-medium">
                      {{item.currency}}
                    </div>
                  </td>
                  <td class="align-top p-0 text-sm">
                    <div class="flex justify-end p-4 font-medium">
                      {{formatCurrency(item.biayaPerBulan, item.currency)}}
                    </div>
                  </td>
                  <td class="align-top p-0 text-sm">
                    <div class="flex justify-end p-4 font-medium">
                      {{formatCurrency(item.unitPrice, item.currency)}}
                    </div>
                  </td>
                  <td class="align-top p-0 text-sm">
                    <div class="flex justify-end p-4 font-medium">
                      {{formatCurrency(item.totalUnitPrice, item.currency)}}
                    </div>
                  </td>
                  <td class="align-top p-0 text-sm">
                    <div class="flex justify-end p-4 font-medium">
                      {{formatCurrency(item.discount, item.currency)}}%
                    </div>
                  </td>
                  <td class="align-top p-0 text-sm">
                    <div class="flex justify-end p-4 font-medium">
                      {{formatCurrency(item.discountPrice, item.currency)}}
                    </div>
                  </td>
                  <td class="align-top p-0 text-sm">
                    <div class="flex justify-end p-4 font-medium">
                      {{formatCurrency(item.finalTotalPrice, item.currency)}}
                    </div>
                  </td>
                </tr>
                <tr v-if="n.costType !== RATE_COST" class="w-full h-full bg-gradient-to-t from-primary-lightest to-white">
                  <td class="sticky left-0 z-20 h-10 p-0 text-sm" colspan="1"><div class="h-10 border-b-2 border-primary"></div></td>
                  <td class="sticky z-20 p-0 text-sm custom-left-2" colspan="5">
                    <div class="flex items-center h-10 truncate border-b-2 border-primary">
                      <div class="flex items-center pr-4">
                        <span class="text-base icon-corner-down-right"></span>
                      </div>
                      <p class="pr-8 text-sm font-medium text-gray-darkest">Total Price: <span class="text-sm font-semibold text-primary">{{ formatCurrency(n.totalPrice, totalCurrency(n)) }}</span></p>
                      <p v-if="!vatIncludedChecker(n.taxType)" class="pr-8 text-sm font-medium text-gray-darkest">VAT ({{n.taxTypePercentage}}%): <span class="text-sm font-semibold text-primary">{{ formatCurrency(n.ppn, totalCurrency(n)) }}</span></p>
                      <p v-else class="pr-8 text-sm font-medium text-gray-darkest">{{ n.taxType }}</p>
                      <p class="pr-8 text-sm font-medium text-gray-darkest">Total Price + VAT: <span class="text-sm font-semibold text-primary">{{ formatCurrency(n.grandTotal, totalCurrency(n)) }}</span></p>
                    </div>
                  </td>
                  <td class="p-0" colspan="12"><div class="h-10 border-b-2 border-primary"></div></td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </gista-modal>
    
    <ExportToExcel :negotiationId="negotiationId" v-if="isOpenExport && menuRfqNegotiationBanReport?.canCreate" :isOpenExport="isOpenExport" @toggleExport="toggleExport"/>
  </div>
</template>

<script>
import { formatCurrency } from '@/core/components/helpers/formatRupiah'
import { vatIncludedChecker } from '@/core/components/helpers'
import ExportToExcel from '@/core/components/rfq/negotiation/ExportToExcel.vue'
export default {
  name: 'MaximizeTable',
  props: ['isOpen', 'negotiationId'],
  data() {
    return {
      isOpenExport: false,
      collapse: [],
      modalText: '',
      RATE_COST: 'RATE_COST',
      maxLength: 34
    }
  },
  computed: {
    negotiationDetail() {
			return this.$store.state.negotiationRfq.negotiationDetail
		},
    title() {
      const data = this.$store.state.negotiationRfq.negotiationOfferItems
      return `${data.negotiationStatus} (${data?.detail?.items?.length} items)`
    },
    disableMaximizeTable() {
			return this.negotiationDetail?.offers?.length === 0
		},
    menuRfqNegotiationBanReport() {
      return this.$store.getters['dashboard/menuRfqNegotiationBanReport']
    },
  },
  watch: {
    negotiationDetail() {
      if(this.negotiationId) this.collapse = this.negotiationDetail?.offers?.map((e) => (e.negotiationId !== this.negotiationId))
      else this.collapse = this.negotiationDetail?.offers?.map((e,i) => (i !== this.negotiationDetail?.offers?.length - 1))
    }
  },
  methods: {
    formatCurrency,
    vatIncludedChecker,
    toggle() {
      this.$emit('clickToggle')
    },
    toggleExport() {
      this.isOpenExport = !this.isOpenExport
    },
    changeCollapse(index) {
      this.collapse = this.collapse?.map((e,i) => (i === index? !e : e))
    },
    totalCurrency(n) {
      return n.offerDetails?.[0]?.currency
    },
  },
  mounted() {
    if(this.negotiationId) {
      this.collapse = this.negotiationDetail?.offers?.map((e) => (e.negotiationId !== this.negotiationId))
    } else {
      this.collapse = this.negotiationDetail?.offers?.map((e,i) => (i !== this.negotiationDetail?.offers?.length - 1))
    }
  },
  components: {
    ExportToExcel
  }
}
</script>

<style scoped>
.height-custom {
  max-height: calc(100% - 4rem);
}
.min-width-custom {
	min-width: 100rem;
}
.custom-left-2 {
  left: 3rem;
}
.custom-left-3 {
  left: 6rem;
}
.custom-left-4 {
  left: 16rem;
}
.custom-left-5 {
  left: 26rem;
}
.min-w-12 {
  min-width: 3rem;
}
.min-w-24 {
  min-width: 6rem;
}
.min-w-40 {
  min-width: 10rem;
}
.min-w-64 {
  min-width: 16rem;
}
@media only screen and (max-width: 639px) {
  .height-custom {
    max-height: calc(100% - 8.5rem);
  }
}

</style>