<template>
	<div class="">
		<div class="w-full overflow-auto max-h-custom">
			<table class="w-full" id="summaryNegotiation" aria-describedby="summaryNegotiation">
				<thead class="sticky top-0 py-1 bg-white">
					<tr class="py-1 border-b-2 border-primary">
						<th scope="col">
							<h5 class="py-2 text-sm font-medium text-left min-w-12 text-gray-darkest">No</h5>
						</th>
						<th scope="col">
							<h5 class="py-2 text-sm font-medium text-left min-w-64 text-gray-darkest">Product/Service</h5>
						</th>
						<th scope="col">
							<h5 class="py-2 text-sm font-medium text-left min-w-64 text-gray-darkest">Sub Product</h5>
						</th>
						<th scope="col">
							<h5 class="py-2 text-sm font-medium text-center min-w-64 text-gray-darkest">Initial Price</h5>
						</th>
						<th scope="col">
							<h5 class="py-2 text-sm font-medium text-center min-w-64 text-gray-darkest">Final Price</h5>
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="n,i in summaryWith?.items" :key="i" :class="i % 2 === 0 ? 'bg-white' : 'bg-primary-lightest bg-opacity-25'">
						<td class="align-top">
							<h5 class="py-2 text-sm text-left min-w-12 text-gray-darkest">{{ n.no ?? '-' }}</h5>
						</td>
						<td class="align-top">
							<h5 v-if="n.product?.length > 20" class="py-2 text-sm text-left min-w-64 text-gray-darkest">{{ n.product.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(n.product)">Read More</span></h5>
							<h5 v-else class="py-2 text-sm text-left min-w-64 text-gray-darkest">{{ n.product }}</h5>
						</td>
						<td class="align-top">
							<h5 v-if="n.subProduct?.length > 20" class="py-2 text-sm text-left min-w-64 text-gray-darkest">{{ n.subProduct.slice(0, 20) }}... <span class="cursor-pointer text-secondary" @click="readMore(n.subProduct)">Read More</span></h5>
							<h5 v-else class="py-2 text-sm text-left min-w-64 text-gray-darkest">{{ n.subProduct }}</h5>
						</td>
						<td class="align-top">
							<h5 class="py-2 text-sm text-right min-w-64 text-primary-darkest">{{ n.initialPrice === 0 ? '-' : formatCurrency(n.initialPrice, n.initialCurrency) }}</h5>
						</td>
						<td class="align-top">
							<h5 class="py-2 text-sm text-right min-w-64 text-primary-darkest">{{ n.finalPrice === 0 ? '-' : formatCurrency(n.finalPrice, n.finalCurrency)  }}</h5>
						</td>
					</tr>
				</tbody>
				<tbody class="sticky bottom-0 bg-gradient-to-t from-primary-lightest to-white">
					<tr class="w-full ">
						<td>
							<div class="flex items-center min-w-12 h-9">
								<span class="icon-corner-down-right"></span>
							</div>
						</td>
						<td>
							<div class="flex items-center w-full border-b min-w-64 h-9 border-primary">
								<h5 class="text-sm font-medium text-gray-darkest">Total Price: </h5>
							</div>
						</td>
						<td>
							<div class="flex items-center w-full border-b min-w-64 h-9 border-primary">
								<h5 class="text-sm font-medium text-gray-darkest"></h5>
							</div>
						</td>
						<td>
							<div class="flex items-center justify-end w-full border-b min-w-64 h-9 border-primary">
								<h5 class="text-sm font-medium text-right text-primary-darkest">{{ formatCurrency(summaryWith?.initialTotalPrice, initTotalCurrency(), true) }}</h5>
							</div>
						</td>
						<td>
							<div class="flex items-center justify-end w-full border-b min-w-64 h-9 border-primary">
								<h5 class="text-sm font-medium text-right text-primary-darkest">{{ formatCurrency(summaryWith?.finalTotalPrice, finalTotalCurrency(), true) }}</h5>
							</div>
						</td>
					</tr>
					<tr class="w-full ">
						<td>
							<div class="min-w-12 h-9"></div>
						</td>
						<td>
							<div class="flex items-center w-full border-b min-w-64 h-9 border-primary">
								<h5 class="text-sm font-medium text-gray-darkest">VAT: </h5>
							</div>
						</td>
						<td>
							<div class="flex items-center w-full border-b min-w-64 h-9 border-primary">
								<h5 class="text-sm font-medium text-gray-darkest"></h5>
							</div>
						</td>
						<td>
							<div class="flex items-center justify-end w-full border-b min-w-64 h-9 border-primary">
								<h5 v-if="!vatIncludedChecker(summaryWith?.initialTaxType)" class="text-sm font-medium text-primary-darkest">{{ formatCurrency(summaryWith?.initialPpnPrice, initTotalCurrency(), true) }} ({{ summaryWith?.initialPpn }}%)</h5>
								<h5 v-else class="text-sm font-medium text-primary-darkest">{{ summaryWith?.initialTaxType }}</h5>
							</div>
						</td>
						<td>
							<div class="flex items-center justify-end w-full border-b min-w-64 h-9 border-primary">
								<h5 v-if="!vatIncludedChecker(summaryWith?.finalTaxType)" class="text-sm font-medium text-primary-darkest">{{ formatCurrency(summaryWith?.finalPpnPrice, finalTotalCurrency(), true) }} ({{ summaryWith?.ppn }}%)</h5>
								<h5 v-else class="text-sm font-medium text-primary-darkest">{{ summaryWith?.finalTaxType }}</h5>
							</div>
						</td>
					</tr>
					<tr class="w-full">
						<td>
							<div class="flex items-center w-full border-b-2 min-w-12 h-9 border-primary">
								<h5 class="text-sm font-medium text-gray-darkest"></h5>
							</div>
						</td>
						<td>
							<div class="flex items-center w-full border-b-2 min-w-64 h-9 border-primary">
								<h5 class="text-sm font-medium text-gray-darkest">Total Price + VAT:</h5>
							</div>
						</td>
						<td>
							<div class="flex items-center w-full border-b-2 min-w-64 h-9 border-primary">
								<h5 class="text-sm font-medium text-gray-darkest"></h5>
							</div>
						</td>
						<td>
							<div class="flex items-center justify-end w-full border-b-2 min-w-64 h-9 border-primary">
								<h5 class="text-sm font-medium text-primary-darkest">{{ formatCurrency(summaryWith?.initialTotalAndPpnPrice, initTotalCurrency(), true) }}</h5>
							</div>
						</td>
						<td>
							<div class="flex items-center justify-end w-full border-b-2 min-w-64 h-9 border-primary">
								<h5 class="text-sm font-medium text-primary-darkest">{{ formatCurrency(summaryWith?.finalTotalAndPpnPrice, finalTotalCurrency(), true) }}</h5>
							</div>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="modalText"/>

	</div>
</template>

<script>
import { formatCurrency } from '@/core/components/helpers/formatRupiah'
import { vatIncludedChecker } from '@/core/components/helpers'
import ModalReadMore from '@/core/components/modal/ReadMore.vue'

export default {
	name: 'negotiationTableSummary',
	data() {
		return {
			modalText: '',
			readMoreShow: false,

		}
	},
	computed: {
		summaryWith(){
      return this.$store.state.negotiationRfq.summaryWith
    },
	},
	methods: {
		formatCurrency,
		vatIncludedChecker,
		closeModal() {
			this.readMoreShow = false
		},
		readMore(text) {
			this.readMoreShow = true;
			this.modalText = text
		},
		initTotalCurrency() {
			return this.summaryWith?.items?.[0]?.initialCurrency
		},
		finalTotalCurrency() {
			return this.summaryWith?.items?.[0]?.finalCurrency
		}
	},
	components: {
		ModalReadMore
	}



}
</script>


<style scoped>
.max-h-custom {
	max-height: 30rem;
}
.min-w-12 {
	min-width: 3rem;
}
.min-w-64 {
	min-width: 16rem;
}
#summaryNegotiation {
	border-collapse: collapse;
}
#summaryNegotiation th {
	padding: 0; 
	margin: 0;
}
</style>