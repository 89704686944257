<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex justify-between my-6">
        <div>
          <span class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"
            @click="clickBackToVendorDetail"></span>
        </div>
        <div>
            <h4 class="text-lg font-medium text-gray-darkest">BAN - {{ banDetails?.negotiationStatus }} </h4>
        </div>
        <div></div>
      </div>
      <div class="flex justify-between mb-6" :class="quotationDetailContainerWidth < 640 ? 'flex-col gap-3' : ''">
        <div class="flex items-center">
          <h5 class="text-base text-gray-darkest">{{ banDetails.vendorName }}</h5>
        </div>
        <div>
          <ButtonGista 
            @click="clickViewBan"
            size="small" 
            type="secondary"
            color="success"
            v-if="menuRfqNegotiationBanReport"
            :class="quotationDetailContainerWidth < 640 ? 'w-full' : ''"
          >
            <template #icon-left>
              <span class="icon-eye text-lg pr-3.5"></span>
            </template>
            View BAN
          </ButtonGista>
        </div>
      </div>
      <div>
        <div class="flex items-center mb-6">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">Revisi Penawaran Harga</h5>
        </div>
        <div class="grid gap-x-6 gap-y-3.5 mb-3.5 border-b border-gray-lightest pb-3.5"
          :class="{
            'grid-cols-1': quotationDetailContainerWidth < 768,
            'grid-cols-2': quotationDetailContainerWidth >= 768
          }">
          <InputDisabled 
            label="Project ID" 
            :value="banDetails.projectId"
          />
          <InputDisabled 
            label="Request No."
            :value="banDetails.requestNo"
          />
          <InputDisabled 
            label="Vendor Name"
            :value="banDetails.vendorName"
          />
          <InputDisabled 
            label="Project Name"
            :value="banDetails.projectName"
          />
          <InputDisabled 
            label="Request By"
            :value="banDetails.requestByName?.toLowerCase()"
            class="capitalize"
          />
          <InputDisabled 
            label="Created By"
            :value="banDetails.createdByName?.toLowerCase()"
            class="capitalize"
          />
          <InputDisabled 
            label="PIC GSIT"
            :value="banDetails.picGsit?.toLowerCase()"
            class="capitalize"
            v-if="workflowGsit"
          />
          <InputDisabled 
            label="PIC UKKP"
            :value="banDetails.picUkkp?.toLowerCase()"
            class="capitalize"
            v-if="!workflowGsit"
          />
          <InputDisabled 
            label="Offer No."
            :value="banDetails.offerNo"
          />
          <InputDisabled 
            label="Offer Date"
            :value="banDetails.offerDate"
          />
          <InputDisabled 
            label="Date Created"
            :value="banDetails.createdAt"
          />
        </div>
        <div class="mb-6">
          <div class="mb-1">
            <p class="text-sm font-medium text-gray">Project Type </p>
          </div>
          <div class="flex flex-wrap">
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('HARDWARE')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Hardware</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('SOFTWARE')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Software</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('SERVICE')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Service</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('MAINTENANCE')"  class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Maintenance</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('LINK_COMMUNICATION')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Link-Communication</p>
            </div>
          </div>
        </div>
        <div class="grid gap-x-6 gap-y-3.5 pb-3.5"
          :class="{
            'grid-cols-1': quotationDetailContainerWidth < 768,
            'grid-cols-2': quotationDetailContainerWidth >= 768
          }">
           <div>
            <div class="flex">
              <label class="block mb-1 text-sm font-medium text-gray">Meeting</label>
            </div>
            <GroupRadioButton class="h-11"
              :options="['Offline', 'Online']"
              mandatory
              name="meeting"
              :selected="banDetails.meeting"
              disabled
            />
          </div>
          <InputDisabled 
            label="Date"
            :value="banDetails.meetingDate"
          />
          <InputDisabled 
            label="Time (Start)"
            :value="banDetails.timeStart"
          />
          <InputDisabled 
            label="Time (End)"
            :value="banDetails.timeEnd"
          />
          <InputDisabled 
            label="Location"
            :value="banDetails.location?banDetails.location:'-'"
            v-if="!meetingOnline"
          />
          <InputDisabled 
            label="Via Online"
            :value="banDetails.viaOnline?banDetails.viaOnline:'-'"
            v-if="meetingOnline"
          />
          <InputDisabled 
            label="Negotiator 1"
            :value="banDetails.nameNegotiator1?banDetails.nameNegotiator1?.toLowerCase():'-'"
            class="capitalize"
          />
          <InputDisabled 
            label="Negotiator 2"
            :value="banDetails.nameNegotiator2?banDetails.nameNegotiator2?.toLowerCase():'-'"
            class="capitalize"
          />
        </div>
        <div class="mb-6">
          <div class="mb-1">
            <p class="text-sm font-medium text-gray">Negotiation Participant</p>
          </div>
          <div class="gap-3.5 flex flex-wrap ">
            <div v-for="n,i in banDetails.negotiationParticipant" :key="i" class="capitalize bg-gray-lightest p-2.5 text-gray text-base border-gray-lightest rounded-lg">{{ n.fullName?.toLowerCase() }}</div>
          </div>
        </div>
        <div class="grid gap-x-6 gap-y-3.5 pb-3.5"
          :class="{
            'grid-cols-1': quotationDetailContainerWidth < 768,
            'grid-cols-2': quotationDetailContainerWidth >= 768
          }">
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Term of Payment 
              </label>
            </div>
            <div class="">
              <TiptapView :value="banDetails.termOfPayment" v-if="banDetails.termOfPayment" />
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div :class="{
            'col-span-2': quotationDetailContainerWidth < 768,
            'col-span-1': quotationDetailContainerWidth >= 768}"
            >
          </div>
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Negotiation Note/ BCA Request 
              </label>
            </div>
            <div class="">
              <TiptapView :value="banDetails.negotiationNote" v-if="banDetails.negotiationNote" />
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Note Internal BCA
              </label>
            </div>
            <div class="">
              <TiptapView :value="banDetails.internalNote" v-if="banDetails.internalNote" />
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div class="col-span-2" v-if="banDetails.proofOfMeetingFileName">
            <div>
              <p class="mb-1 text-sm font-medium text-gray">Proof of Negotiation Meeting</p>
            </div>
            <div class="flex items-center justify-between border rounded-lg border-primary min-h-file">
              <div class="px-4 py-2 text-base text-gray" :title="banDetails.proofOfMeetingFileName">{{ banDetails.proofOfMeetingFileName }}</div>
              <p class="px-4 text-2xl cursor-pointer icon-eye text-primary" @click="openFile(banDetails.proofOfMeetingFileName, banDetails.proofOfMeetingDocumentNo)"></p>
            </div>
          </div>
          <div class="col-span-2" v-for="n,i in fileQuotation" :key="i">
            <div>
              <p class="mb-1 text-sm font-medium text-gray">File Quotation</p>
            </div>
            <div class="flex items-center justify-between border rounded-lg border-primary min-h-file">
              <div class="px-4 py-2 text-base text-gray" :title="n.fileName">{{ n.fileName }}</div>
              <p class="px-4 text-2xl cursor-pointer icon-eye text-primary" @click="openFile(n.fileName, n.documentNo)"></p>
            </div>
          </div>
          <div class="col-span-2" v-for="n,i in fileAttachment" :key="i">
            <div>
              <p class="mb-1 text-sm font-medium text-gray">Attachment #{{i+1}}</p>
            </div>
            <div class="flex items-center justify-between border rounded-lg border-primary min-h-file">
              <div class="px-4 py-2 text-base text-gray" :title="n.fileName">{{ n.fileName }}</div>
              <p class="px-4 text-2xl cursor-pointer icon-eye text-primary" @click="openFile(n.fileName, n.documentNo)"></p>
            </div>
          </div>
          <div :class="quotationDetailContainerWidth < 640? 'col-span-2': 'col-span-1'">
            <div class="mb-1">
              <p class="text-sm font-medium text-gray">Cost Type</p>
            </div>
            <div class="flex h-12">
              <radio-gista disabled :modelValue="negotiationOfferGet?.costType === 'FIX_COST'? 'FIX_COST': ''" nativeValue="FIX_COST" class="pr-4 text-gray">Fixed</radio-gista>
              <radio-gista disabled :modelValue="negotiationOfferGet?.costType === 'RATE_COST'? 'RATE_COST': ''" nativeValue="RATE_COST" class="pr-4 text-gray">Rate</radio-gista>
              <radio-gista disabled :modelValue="negotiationOfferGet?.costType === 'MIX_COST'? 'MIX_COST': ''" nativeValue="MIX_COST" class="text-gray">Mixed</radio-gista>
            </div>
          </div>
          <div :class="quotationDetailContainerWidth < 640? 'col-span-2': 'col-span-1'">
            <InputDisabled label="VAT" :value="taxText"></InputDisabled>
          </div>
        </div>
        <div class="pb-6">
          <div class="flex flex-wrap items-center justify-between w-full gap-3 mt-3 mb-6">
            <div class="flex items-center pt-3 pr-4">
              <p class="text-2xl font-bold cursor-pointer icon-list text-gray"></p>
              <h4 class="pl-3 text-lg font-medium">Negotiation details</h4>
            </div>
            <button class="flex items-center" :class="`${disableMaximizeTable? 'cursor-not-allowed': 'cursor-pointer hover:text-gray-dark active:text-gray-darkest'} ${quotationDetailContainerWidth < 640 ? '' : 'pl-4'}`"
                @click="clickToggleMaximizeTable"
                :disabled="disableMaximizeTable"
                >
              <span class="mr-3 text-lg icon-maximize text-gray"></span>
              <p class="text-sm font-semibold text-gray" >Maximize table</p>
            </button>
          </div>
          <MaximizeTable :negotiationId="this.banDetails.rfqNegotiationId" v-if="menuRfqNegotiationOfferGet?.canRead && isOpenMaximizeTable" :isOpen="isOpenMaximizeTable" @clickToggle="clickToggleMaximizeTable"/>
          <NegotiationTableDetails :negotiationId="this.banDetails.rfqNegotiationId" v-if="menuRfqNegotiationOfferGet?.canRead" />
        </div>
      </div>
    </div>
    <BanLoading v-else/>
  </div>
</template>

<script>
import GroupRadioButton from "@/core/components/custom/GroupRadioButton.vue";
import TiptapView from '@/core/components/custom/TiptapView.vue'
import NegotiationTableDetails from '@/core/components/rfq/negotiation/NegotiationTableDetails.vue';
import MaximizeTable from '@/core/components/rfq/negotiation/MaximizeTable.vue';
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import BanLoading from '@/core/components/rfq/loading/BanLoading.vue'

export default {
  name: 'DetailBanOfferRevision',
  data() {
    return {
      isOpenMaximizeTable: false,
      time: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
      isLoading: false,
    }
  },
  computed: {
    negotiationDetail(){
      return this.$store.state.negotiationRfq.negotiationDetail
    },
    disableMaximizeTable() {
      return this.negotiationDetail?.offers?.length === 0
    },
    banDetails: {
      get() {
        return this.$store.state.negotiationRfq.banDetails
      },
      set(value) {
        this.$store.commit('negotiationRfq/SET_NEGOTIATION_OFFER_BAN', value)
      }
    },
    banNegotiationId() {
      return this.$store.state.negotiationRfq.banNegotiationId
    },
    quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
    fileQuotation() {
      return this.banDetails?.attachments?.filter(i => i.fileRef === 'fileQuotation')
    },
    fileAttachment() {
      return this.banDetails?.attachments?.filter(i => i.fileRef === 'lampiranBan')
    },
    negotiationOfferGet() {
      return this.$store.state.negotiationRfq.negotiationOfferGet
    },
    taxText() {
      return this.negotiationOfferGet?.tax?.ppn === 0 ? this.negotiationOfferGet?.tax?.taxType : `${this.negotiationOfferGet?.tax?.taxType} - ${this.negotiationOfferGet?.tax?.ppn}%`  
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    menuRfqNegotiationOfferGet() {
			return this.$store.getters['dashboard/menuRfqNegotiationOfferGet']
    },
    menuRfqNegotiationBanReport() {
      return this.$store.getters['dashboard/menuRfqNegotiationBanReport']?.canRead
    },
    workflowGsit() {
      return this.banDetails.isUnitGsit
    },
    meetingOnline() {
      return this.banDetails.meeting === 'Online'
    },
  },
  methods: {
    openFile(filename, documentNo) {
      this.$store.dispatch('file/viewAttachment', { filename, documentNo })
    },
    clickBackToVendorDetail() {
      this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_OFFER', false)
      this.$router.go(-1)
    },
    async clickViewBan() {
      this.modal.modalLoading = true
      await this.$store.dispatch('negotiationRfq/getBanReport', { negotiationId:  this.banDetails.rfqNegotiationId })
      this.modal.modalLoading = false
    },
    clickToggleMaximizeTable() {
      this.isOpenMaximizeTable = !this.isOpenMaximizeTable
    },
  },
  async mounted(){
    this.isLoading = true
		const response = await this.$store.dispatch('negotiationRfq/getNegotiationList', {rfqId:this.$route.params.quotationId})
    const r1 = this.$store.dispatch('negotiationRfq/getNegotiationOfferBan', {negotiationId:this.$route.query.negotiation})
    const r2 = this.$store.dispatch('negotiationRfq/getNegotiationOffer', {negotiationId:this.$route.query.negotiation})
    const [res1, res2] = await Promise.all([r1, r2])
    const r3 = this.$store.dispatch('negotiationRfq/getNegotiationOfferItems', { negotiationId:this.negotiationDetail.offers[0].negotiationId})
    const r4 = this.$store.dispatch('negotiationRfq/getNegotiationList',{rfqId:this.$route.params.quotationId})
    const [res3, res4] = await Promise.all([r3, r4])
    if ([res1?.status, res2?.status, res3?.status, res4?.status, response?.status].every(e => e === 200)) {
      this.isLoading = false
    }
  },
  destroyed() {
    this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_OFFER', false)
  },
  components: {
    TiptapView,
    NegotiationTableDetails,
    MaximizeTable,
    GroupRadioButton,
    InputDisabled,
    BanLoading
  }

}
</script>

<style scoped>
.min-w-custom {
  min-width: 40rem;
}
.min-h-file {
  min-height: 3.5rem;
}
</style>