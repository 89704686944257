<template>
  <div class="w-full">
    <div class="grid gap-x-6 gap-y-2.5 mb-2.5"
      :class="{
        'grid-cols-1': quotationDetailContainerWidth < 768,
        'grid-cols-2': quotationDetailContainerWidth >= 768
      }"
    >
      <InputDisabled 
        v-if="isSubmitted"
        label="Approval No." 
        :value="dataSpp?.sppNo"
      />
      <InputDisabled 
        v-else
        label="Project ID" 
        :value="quotationInformation?.projectId"
      />
      <InputDisabled 
        label="Request No."
        :value="dataSpp?.noRfq"
      />
    </div>
    <div class="mb-6">
      <InputDisabled 
        label="Project Name"
        :value="dataSpp?.projectName"
      />
    </div>
    
  </div>
</template>
  
<script>
import InputDisabled from '@/core/components/custom/InputDisabled.vue'

export default {
  name: "Information",
  props: ['readOnly'],
  data(){
    return {
    }
  },
  computed:{
    quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
    dataSpp: {
      get() {
        return this.$store.state.sppRfq.dataSpp
      },
      set(value) {
        this.$store.commit('sppRfq/SET_DATA_SPP', value)
      }
    },
    quotationInformation() {
			return this.$store.state.quotationDetail.quotationInformation
		},
    isSubmitted() {
      return !!(this.dataSpp.statusDocument === 'SUBMITTED')
    },
  },
  methods: {
  },
  mounted() {
  },
  components:{
    InputDisabled,
  }
}
</script>