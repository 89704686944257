<template>
	<div class="mb-4">
		<div class="w-full overflow-x-auto">
			<table class="w-full min-width-table" aria-describedby="detailBan">
				<thead class="border-b-2 border-primary">
					<tr>
						<th scope="col" class="py-2 pr-4 text-sm font-medium text-left text-gray-darkest truncate">
							Negotiation
						</th>
						<th scope="col" class="py-2 pr-4 text-sm font-medium text-left text-gray-darkest truncate">
							Offer Date
						</th>
						<th scope="col" class="py-2 pr-4 text-sm font-medium text-left text-gray-darkest truncate">
							Negotiation Date
						</th>
						<th scope="col" class="py-2 pr-4 text-sm font-medium text-left text-gray-darkest truncate">
							Time
						</th>
						<th scope="col" class="py-2 pr-4 text-sm font-medium text-left text-gray-darkest truncate">
							Location / Via Online
						</th>
						<th scope="col" class="py-2 text-sm font-medium text-left text-gray-darkest truncate">
							Offer No.
						</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(n,i) in items.negotiationHistories" :key="i" :class="i % 2 === 0 ? 'bg-white' : 'bg-primary-lightest bg-opacity-25'">
						<td class="py-2 pr-4 text-sm font-medium text-gray-dark">
							{{ n.negotiationStatus }}
						</td>
						<td class="py-2 pr-4 text-sm font-medium text-gray-dark truncate">
							{{ formatDate3(n.offerDate) || '-' }}
						</td>
						<td class="py-2 pr-4 text-sm font-medium text-gray-dark truncate">
							{{ formatDate3(n.meetingDate) || '-' }}
						</td>
						<td class="py-2 pr-4 text-sm font-medium text-gray-dark truncate">
							{{ n?.timeStart || n?.timeEnd? `${n?.timeStart} - ${n?.timeEnd} WIB`: '-' }}
						</td>
						<td class="py-2 pr-4 text-sm font-medium text-gray-dark">
							{{ n.location || n.viaOnline || '-' }}
						</td>
						<td class="py-2 text-sm font-medium text-gray-dark">
							{{ n.offerNo }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div v-if="items.negotiationHistories?.length === 0" class="flex items-center justify-center w-full py-6">
			<h4 class="text-sm font-medium text-warning-darkest">
				No data. Please upload the initial offer from vendor.
			</h4>
		</div>
	</div>
</template>

<script>
	import { formatDate3 } from '@/core/components/helpers/format.js'
	export default {
		name: 'NegotiationDealTable',
		props: {
			items: {
				type: [Array, Function],
      	default: () => [],
			}
		},
		methods: {
			formatDate3
		}
	}
</script>

<style scoped>
.min-width-table {
  min-width: 56rem;
}
</style>