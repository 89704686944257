<template>
  <div>
    <ButtonGista 
      @click="toggle"
      color="success"
      customClass="w-full -sm:w-max">
      Create
    </ButtonGista>
    <GistaModal :value="isOpen"
      width="2/3"
      scrollable
      @input="toggle"
      customClass="max-w-2xl bg-white">
      <div>
        <div class="w-full p-6">
          <div class="flex justify-center p-3 mb-6">
            <h1 class="text-xl text-gray">{{ title }}</h1>
          </div>
          <div class="flex justify-center p-3 mb-6">
            <p class="text-gray">Pastikan seluruh informasi sudah benar</p>
          </div>
          <div class="flex flex-col justify-center gap-3 sm:flex-row sm:gap-0">
            <button-gista color="success"
              customClass="w-full sm:w-56"
              @click="clickSubmit">Create</button-gista>
          </div>
        </div>
      </div>
    </GistaModal>
  </div>
</template>

<script>
export default {
  name: 'SubmitLoi',
  props: {
    title: String,
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
    clickSubmit() {
      this.toggle()
      this.$emit('click')
    }
  }
}
</script>