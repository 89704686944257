<template>
  <div>
    <div class="mb-4 overflow-x-auto border rounded-lg border-gray-light">
      <table class="w-full" aria-describedby="approval">
        <thead>
          <tr>
            <th scope="col" class="p-2" colspan="12">
              <p class="text-sm font-medium text-left text-gray">Approval</p>
            </th>
          </tr>
          <tr class="bg-primary-lightest">
            <th v-if="reviewerDlog?.username" scope="col" :colspan="reviewerDlog?.username && approverDlog?.username? '1' : '2'" :class="`${reviewerDlog?.username && approverDlog?.username? 'w-1/2' : 'w-full'}`" class="p-2 border-t border-gray-light">
              <p class="text-sm font-medium text-left text-gray">Reviewer DLOG</p>
            </th>
            <th v-if="approverDlog?.username" scope="col" :colspan="reviewerDlog?.username && approverDlog?.username? '1' : '2'" :class="`${reviewerDlog?.username && approverDlog?.username? 'w-1/2 border-l' : 'w-full'}`" class="p-2 border-t border-gray-light">
              <p class="text-sm font-medium text-left text-gray">Approver DLOG</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-if="reviewerDlog?.username" class="p-2 border-gray-light" :colspan="reviewerDlog?.username && approverDlog?.username? '1' : '2'" >
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ reviewerDlog?.name }}</p>
              <p v-if="reviewerDlog?.approvalDate"
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="colorTextStatus(reviewerDlog?.approvalStatus)">{{ mapTextStatus(reviewerDlog?.approvalStatus)}}</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">{{ formatDate4(reviewerDlog?.approvalDate) }}</span> 
              </p>
              <p v-else-if="reviewerDlog?.approvalStatus === 'AWAITING_APPROVAL' && !someInProgress" class="mt-1 text-sm font-medium capitalize whitespace-nowrap text-warning-dark">On Review</p>
              <p v-else class="min-height-p"></p>
            </td>
            <td v-if="approverDlog?.username" class="p-2 border-gray-light" :class="`${reviewerDlog?.username? 'border-l' : ''}`" :colspan="reviewerDlog?.username && approverDlog?.username? '1' : '2'" >
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ approverDlog?.name }}</p>
              <p v-if="approverDlog?.approvalDate"
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="colorTextStatus(approverDlog?.approvalStatus)">{{ mapTextStatus(approverDlog?.approvalStatus)}}</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">{{ formatDate4(approverDlog?.approvalDate) }}</span> 
              </p>
              <p v-else-if="approverDlog?.approvalStatus === 'AWAITING_APPROVAL' && !someInProgress" class="mt-1 text-sm font-medium capitalize whitespace-nowrap text-warning-dark">On Review</p>
              <p v-else class="min-height-p"></p>
            </td>
          </tr>
        </tbody>
        <thead>
          <tr class="bg-primary-lightest">
            <th v-if="reviewerGsit?.username" scope="col" :colspan="reviewerGsit?.username && approverGsit?.username? '1' : '2'" :class="`${reviewerGsit?.username && approverGsit?.username? 'w-1/2' : 'w-full'}`" class="p-2 border-t border-gray-light">
              <p class="text-sm font-medium text-left text-gray">Reviewer GSIT/UKKP</p>
            </th>
            <th v-if="approverGsit?.username" scope="col" :colspan="reviewerGsit?.username && approverGsit?.username? '1' : '2'" :class="`${reviewerGsit?.username && approverGsit?.username? 'w-1/2 border-l' : 'w-full'}`" class="p-2 border-t border-gray-light">
              <p class="text-sm font-medium text-left text-gray">Approver GSIT/UKKP</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td v-if="reviewerGsit?.username" class="p-2 border-gray-light" :colspan="reviewerGsit?.username && approverGsit?.username? '1' : '2'" >
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ reviewerGsit?.name }}</p>
              <p v-if="reviewerGsit?.approvalDate"
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="colorTextStatus(reviewerGsit?.approvalStatus)">{{ mapTextStatus(reviewerGsit?.approvalStatus)}}</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">{{ formatDate4(reviewerGsit?.approvalDate) }}</span> 
              </p>
              <p v-else-if="reviewerGsit?.approvalStatus === 'AWAITING_APPROVAL' && !someInProgress" class="mt-1 text-sm font-medium capitalize whitespace-nowrap text-warning-dark">On Review</p>
              <p v-else class="min-height-p"></p>
            </td>
            <td v-if="approverGsit?.username" class="p-2 border-gray-light" :class="`${reviewerGsit?.username? 'border-l' : ''}`" :colspan="reviewerGsit?.username && approverGsit?.username? '1' : '2'" >
              <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{ approverGsit?.name }}</p>
              <p v-if="approverGsit?.approvalDate"
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="colorTextStatus(approverGsit?.approvalStatus)">{{ mapTextStatus(approverGsit?.approvalStatus)}}</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">{{ formatDate4(approverGsit?.approvalDate) }}</span> 
              </p>
              <p v-else-if="approverGsit?.approvalStatus === 'AWAITING_APPROVAL' && !someInProgress" class="mt-1 text-sm font-medium capitalize whitespace-nowrap text-warning-dark">On Review</p>
              <p v-else class="min-height-p"></p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import { formatDate4 } from '@/core/components/helpers/format';

export default {
  name: 'TableApprovalProcurement',
  data() {
    return {
      waiting: false
    }
  },
  computed: {
    dataSpp: {
      get() {
        return this.$store.state.sppRfq.dataSpp
      },
      set(value) {
        this.$store.commit('sppRfq/SET_DATA_SPP', value)
      }
    },
    widthApproval() {
      return 'w-1/2'
    },
    reviewerDlog() {
      return this.dataSpp.approvals.find(e => e.approvalType === 'REVIEWER_DLOG')
    },
    approverDlog() {
      return this.dataSpp.approvals.find(e => e.approvalType === 'APPROVER_DLOG')
    },
    reviewerGsit() {
      return this.dataSpp.approvals.find(e => e.approvalType === 'REVIEWER_GSIT')
    },
    approverGsit() {
      return this.dataSpp.approvals.find(e => e.approvalType === 'APPROVER_GSIT')
    },
		someInProgress() {
			return this.$store.state.projectLog.projectLogRfq?.rfqLogs?.some(e => e.isInProgress || e.additionalLogs?.some(el => el.isInProgress))
		},
  },
  methods: {
    formatDate4,
    mapTextStatus(status) {
      if (status === 'APPROVE') {
        return 'Approved'
      } else {
        return 'Rejected'
      }
    },
    colorTextStatus(status) {
      if (status === 'APPROVE') {
        return 'text-success'
      } else {
        return 'text-error'
      }
    }
  },
  components: {
  }
}
</script>