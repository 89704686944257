<template>
	<div class="w-full">
		<ButtonGista color="secondary"
			customClass="w-full"
			@click="() => isOpen = true"
			:disabled="negotiationDetail?.isCanceled"
			>
			Negotiation Scheduled
		</ButtonGista>
		<gista-modal width="1/2"
			:value="isOpen"
			@input="closeModal"
			customClass="max-w-2xl bg-white"
			:scrollable="true">
			<div class="w-full p-6">
				<div class="flex justify-center p-3 mb-6">
					<h1 class="text-xl text-gray">Apakah negosiasi sudah dijadwalkan?</h1>
				</div>
				<div class="flex justify-center p-3 mb-6">
					<p class="text-gray">Negosiasi dijadwalkan diluar sistem SOLUTIP </p>
				</div>
				<div class="flex flex-col justify-center gap-5 sm:flex-row">
					<button-gista type="tertiary"
						color="gray"
						customClass="w-full sm:w-56 border border-gray-lightest shadow-gray-sm"
						@click="closeModal">Back</button-gista>
					<button-gista color="secondary"
						customClass="w-full sm:w-56"
						@click="click">Yes, Scheduled</button-gista>
				</div>
			</div>
		</gista-modal>
	</div>
</template>

<script>
export default {
	name: "NegotiationScheduled",
	data() {
		return {
			isOpen: false,
		}
	},
	computed: {
		negotiationDetail(){
			return this.$store.state.negotiationRfq.negotiationDetail
		},

	},
	methods: {
	async	click() {
			try {
				// console.log(e, "test click")
				const response = await this.$store.dispatch('negotiationRfq/putNegotiationSchedule', {rfqId:this.negotiationDetail.rfqId})
				console.log(response, "response get negotiation schedule")
				this.$emit("click")
				this.isOpen = false
				this.$store.dispatch('negotiationRfq/getNegotiationList',{rfqId:this.$route.params.quotationId})
			} catch (error) {
				console.log(error)
			}
			
		},
		closeModal() {
			this.isOpen = false
		}
	}
}
</script>