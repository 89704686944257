<template>
  <div class="w-full">
    <div class="grid gap-x-6 gap-y-2.5 mb-2.5"
      :class="{
        'grid-cols-1': quotationDetailContainerWidth < 768,
        'grid-cols-2': quotationDetailContainerWidth >= 768
      }"
    >
      <InputDisabled 
        label="Project ID" 
        :value="quotationInformation.projectId"
      />
      <InputDisabled 
        label="Request No."
        :value="quotationInformation.noRfq"
      />
    </div>
    <div class="mb-6">
      <InputDisabled 
        label="Project Name"
        :value="quotationInformation.projectName"
      />
    </div>
  </div>
</template>

<script>
import InputDisabled from '@/core/components/custom/InputDisabled.vue'

export default {
  name: "Information",
  data(){
    return {
    }
  },
  computed:{
    quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
    quotationInformation() {
			return this.$store.state.quotationDetail.quotationInformation
		},
  },
  mounted(){
  },
  components:{
    InputDisabled,
  }
}
</script>