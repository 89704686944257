<template>
  <div class="w-full">
    <Attachment :readOnly="false"/>
    <div class="grid gap-x-6 gap-y-3.5 mb-3.5 "
      :class="{
        'grid-cols-1': projectDetailContainerWidth < 768,
        'grid-cols-2': projectDetailContainerWidth >= 768
      }"
    >
      <template>
        <AutocompleteNewSolutip
          label="Reviewer ITP"
          placeholder="Search"
          :options="approverList"
          @updateInput="inputSearchApprover($event, 'REVIEWER_DLOG')"
          @updateAutoComplete="changeApprover($event, 'REVIEWER_DLOG')"
          @focus="focusApprover('REVIEWER_DLOG')"
          @blur="blurApprover('REVIEWER_DLOG')"
					:isLoading="isLoading.REVIEWER_DLOG"
          v-model="approvals.REVIEWER_DLOG.search"
          textField="name"
          textField2="jobTitle"
          iconLeft="icon-search text-xl text-gray-light"
        >
        </AutocompleteNewSolutip>
      </template>
      <template>
        <AutocompleteNewSolutip
          label="Approver ITP"
          placeholder="Search"
          :options="approverList"
          @updateInput="inputSearchApprover($event, 'APPROVER_DLOG')"
          @updateAutoComplete="changeApprover($event, 'APPROVER_DLOG')"
          @focus="focusApprover('APPROVER_DLOG')"
          @blur="blurApprover('APPROVER_DLOG')"
          :isLoading="isLoading.APPROVER_DLOG"
          v-model="approvals.APPROVER_DLOG.search"
          :error="!!draftLoi?.error?.['Approver ITP']"
          textField="name"
          textField2="jobTitle"
          iconLeft="icon-search text-xl text-gray-light"
          mandatory
        >
          <template #message>
            <p class="mt-1 text-xs text-error">{{ draftLoi.error['Approver ITP'] }}</p>
          </template>
        </AutocompleteNewSolutip>
      </template>
      <template>
        <AutocompleteNewSolutip
          label="Reviewer GSIT"
          placeholder="Search"
          :options="approverList"
          @updateInput="inputSearchApprover($event, 'REVIEWER_GSIT')"
          @updateAutoComplete="changeApprover($event, 'REVIEWER_GSIT')"
          @focus="focusApprover('REVIEWER_GSIT')"
          @blur="blurApprover('REVIEWER_GSIT')"
					:isLoading="isLoading.REVIEWER_GSIT"
          v-model="approvals.REVIEWER_GSIT.search"
          textField="name"
          textField2="jobTitle"
          iconLeft="icon-search text-xl text-gray-light"
        >
        </AutocompleteNewSolutip>
      </template>
      <template>
        <AutocompleteNewSolutip
          label="Approver GSIT"
          placeholder="Search"
          :options="approverList"
          @updateInput="inputSearchApprover($event, 'APPROVER_GSIT')"
          @updateAutoComplete="changeApprover($event, 'APPROVER_GSIT')"
          @focus="focusApprover('APPROVER_GSIT')"
          @blur="blurApprover('APPROVER_GSIT')"
          :isLoading="isLoading.APPROVER_GSIT"
          :error="!!draftLoi?.error?.['Approver GSIT']"
          v-model="approvals.APPROVER_GSIT.search"
          textField="name"
          textField2="jobTitle"
          iconLeft="icon-search text-xl text-gray-light"
          mandatory
        >
          <template #message>
            <p class="mt-1 text-xs text-error">{{ draftLoi.error['Approver GSIT'] }}</p>
          </template>
        </AutocompleteNewSolutip>
      </template>
    </div>
  </div>
</template>

<script>
import AutocompleteNewSolutip from '@/core/components/custom/AutocompleteNew'
import { MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'
import Attachment from '@/core/components/rfq/loi/Attachment.vue';

export default {
  name: "Approval",
  props: ['disabled', 'readOnly'],
  data(){
    return {
			isLoading: {
				APPROVER_GSIT: false,
				REVIEWER_GSIT: false,
				APPROVER_DLOG: false,
				REVIEWER_DLOG: false,
			},
			error: {
				APPROVER_GSIT: false,
				REVIEWER_GSIT: false,
				APPROVER_DLOG: false,
				REVIEWER_DLOG: false,
			},
      applicationRoles: {
        REVIEWER_DLOG: 'SOLUTIP_SPV_LOG,SOLUTIP_SPV1_LOG,SOLUTIP_SPV2_LOG,SOLUTIP_SPV3_LOG',
        APPROVER_DLOG: 'SOLUTIP_SPV1_LOG,SOLUTIP_SPV2_LOG,SOLUTIP_SPV3_LOG',
        REVIEWER_GSIT: 'SOLUTIP_SPV1_GSIT,SOLUTIP_SPV2_GSIT,SOLUTIP_SPV3_GSIT',
        APPROVER_GSIT: 'SOLUTIP_SPV1_GSIT,SOLUTIP_SPV2_GSIT,SOLUTIP_SPV3_GSIT,SOLUTIP_EVP',
        REVIEWER_UKKP: 'SOLUTIP_SPV1_KP,SOLUTIP_SPV2_KP,SOLUTIP_SPV3_KP',
        APPROVER_UKKP: 'SOLUTIP_SPV1_KP,SOLUTIP_SPV2_KP,SOLUTIP_SPV3_KP',
			},
      errorFileType: false,
      eselon: 's1,s2,s3,s4',
      REVIEWER_DLOG: 'REVIEWER_DLOG'
    }
  },
  computed:{
    projectDetailContainerWidth() {
      return this.$store.state.width.projectDetailContainerWidth
    },
    approvals: {
      get() {
        return this.$store.state.loiRfq.approvals
      },
      set(value) {
        this.$store.commit('loiRfq/SET_APPROVALS', value)
      }
    },
    approverList() {
			return this.$store.state.rfp.approverList.filter(e => !this.filterForApproverList.includes(e.userName))
		},
		filterForApproverList() {
			const people = []
      for (const key in this.approvals) {
        if (this.approvals[key].username) {
          people.push(this.approvals[key].username)
        }
      }
      return people
		},
    draftLoi: {
      get() {
        return this.$store.state.loiRfq.draftLoi
      },
      set(value) {
        this.$store.commit('loiRfq/SET_DRAFT_LOI', value)
      }
    }
  },
  methods: {
    async getApproverList(key) {
      this.isLoading[key] = true
      await this.$store.dispatch('rfp/getApproverList', { applicationRoles: this.applicationRoles[key], name: this.approvals[key].search, eselon: key === this.REVIEWER_DLOG? this.eselon : '' })
      this.isLoading[key] = false
    },
    inputSearchApprover(e, key) {
      this.getApproverList(key)
		},
		focusApprover(key) {
      this.getApproverList(key)
		},
		async changeApprover(e, key) {
      this.error[key] = false
      this.approvals[key]= { 
        username: e.userName, 
        name: e.name,
        compositeName: e.compositeName,
        division: e.unitKerja,
        jobTitle: e.jobTitle,
        approvalType: key 
      }
      this.approvals[key].search = e.name
      this.setError(key, '')
		},
		blurApprover(key) {
			if (!this.approvals[key].search) {
				this.approvals[key] = {}
        this.setError(key, MESSAGE_EMPTY_FIELD)
			} else {
        this.approvals[key] = { ...this.approvals[key], search: this.approvals[key].name }
      }
		},
    setError(key, value) {
      if (key === 'APPROVER_DLOG') {
        this.draftLoi.error['Approver ITP'] = value
      }
      if (key === 'APPROVER_GSIT') {
        this.draftLoi.error['Approver GSIT'] = value
      }
    },
  },
  mounted(){
  },
  components:{
    AutocompleteNewSolutip,
    Attachment
  }
}
</script>