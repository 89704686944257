<template>
  <div>
    <div class="flex mt-6">
      <div class="w-full animate-pulse">
        <div class="flex">
          <div class="mr-6">
            <span class="icon-arrow-left text-gray text-2xl font-bold cursor-pointer"
              @click="backToVendorList"></span>
          </div>
          <div class="h-12 bg-gray-lightest rounded-lg w-full mb-3.5"></div>
        </div>
        <div v-for="n in 2" :key="n"  class="h-14 bg-gray-lightest rounded-lg w-full mb-3.5"></div>
        <div class="grid gap-3.5 mb-3.5" :class="quotationDetailContainerWidth > 768 ? 'grid-cols-2': 'grid-cols-1'">
          <div v-for="i in 8" :key="i" class="h-16 bg-gray-lightest rounded-lg w-full" ></div>
        </div>
        <div class="h-32 bg-gray-lightest rounded-lg w-full mb-3.5"></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    name: "BanLoading",
    computed: {
      quotationDetailContainerWidth() {
        return this.$store.state.width.quotationDetailContainerWidth
      },
    },
    methods: {
      backToVendorList() {
        this.$store.commit('negotiationRfq/SET_SHOW_REVISE_OFFER', false)
        this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_OFFER', false)
        setTimeout(() => {
          this.$store.commit('negotiationRfq/SET_SHOW_DEAL_BAN', false)
          this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_BAN', false)
          this.$store.commit('negotiationRfq/SET_SHOW_FPTN', false)
          this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_FPTN', false)
        }, 200)
      },
    }
}
</script>