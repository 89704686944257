<template>
  <div :class="customClass">
    <ButtonGista :class="customClass" type="secondary" color="primary" @click="changeIsOpen(true)">Save As Draft</ButtonGista>
    <ModalConfirm 
      title="Simpan sebagai Draft" 
      message="Anda dapat melanjutkan FPTN di detail negosiasi" 
      icon="saved" textButton="Save As Draft" 
      :isOpen="isOpenFptnSaveAsDraft" 
      @changeIsOpen="changeIsOpen($event)" 
      @click="clickSendEmail">
    </ModalConfirm>
  </div>
</template>

<script>
  import ModalConfirm from '@/core/components/modal/Confirm'

  export default {
    name: 'FptnSaveAsDraft',
    props: ['isOpenFptnSaveAsDraft', 'customClass'],
    methods: {
      changeIsOpen(val) {
        this.$emit('clickToggle',val)
      },
      clickSendEmail() {
        console.log('send email');
        this.$emit('click')
      }
    },
    components: {
      ModalConfirm
    }
    

  }
</script>