<template>
  <div>
    <div class="w-full mb-3 border rounded-lg border-gray-lightest" v-if="vendor.negotiationResultDetails">
      <div class="flex">
        <div class="flex-1 mb-1 p-3.5">
          <h5 class="mb-1 text-sm font-medium text-tertiary">Vendor #{{ vendor?.vendorSeq }}</h5>
          <h5 class="text-base font-medium text-gray-darkest">{{ vendor?.vendorName }}</h5>
          <p v-if="errorSelectOne" class="text-sm text-error">Wajib memilih satu product/service</p>
        </div>
        <div class="flex flex-none">
          <div v-if="!vendor?.collapse" class="py-3.5">
            <p class="flex justify-end text-sm font-medium text-gray">Grand Total In IDR</p>
            <p class="flex justify-end text-lg font-bold text-primary-darkest">{{ formatCurrency(vendor.negotiationResultDetails.grandTotalIdr) }}</p>
          </div>
          <div v-if="vendor?.collapse" class="flex py-4 cursor-pointer" @click="openModal">
            <span class="mr-3 text-lg icon-maximize text-gray hover:text-gray-dark"></span>
            <p class="text-sm font-semibold text-gray hover:text-gray-dark">Maximize Table</p>
          </div>
          <div>
            <p class="p-4 text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray" :class="vendor?.collapse ? 'rotate-180' : 'rotate-0'" @click="changeCollapseVendor"></p>
          </div>
        </div>
      </div>
      <ResultTable :readOnly="readOnly" v-if="vendor?.collapse"/>
      <div class="col-span-2 px-3.5 pt-3.5 my-1">
        <div class="flex items-center">
          <div class="block mb-1 text-sm text-gray" :class="vendor.errorMaxLength['Note Internal BCA']? 'text-error' : 'text-gray'">
            Note Internal BCA
          </div>
        </div>
        <div class="">
          <Tiptap v-if="(!isSubmitted || isRejected) && !readOnly" @char="(e) => characterCount(e, 'Note Internal BCA')" v-model="vendor.negotiationResultDetails.noteInternal" :error="!!vendor.errorMaxLength['Note Internal BCA']">
            <template #message>
              <p>{{ vendor.errorMaxLength['Note Internal BCA'] }}</p>
            </template>
          </Tiptap>
          <TiptapView v-else-if="isSubmitted" v-model="vendor.negotiationResultDetails.noteInternal" />
          <InputDisabled value="-" v-else/>
        </div>
      </div>
      <div class="col-span-2 p-3.5 my-1">
        <div class="flex items-center">
          <p class="block mb-1 text-sm text-gray">
            Term of Payment
          </p>
        </div>
        <div class="">
          <TiptapView v-model="vendor.negotiationResultDetails.termOfPayment" />
        </div>
      </div>
    </div>
    <gista-modal
      :value="isOpenMaximize"
			:scrollable="true"
      width="11/12"
			@input="closeModal()"
			customClass="bg-white mt-6 mb-8 max-w-screen-2xl" id="modal">
      <div id="modal-title">
        <div class="flex justify-center py-6">
          <h5 class="text-lg font-medium text-gray-darkest">Negotiation Result</h5>
        </div>
        <div class="mb-1 p-3.5">
          <h5 class="mb-1 text-sm font-medium text-tertiary">Vendor #{{ vendor?.vendorSeq }}</h5>
          <h5 class="text-base font-medium text-gray-darkest">{{ vendor?.vendorName }}</h5>
          <p v-if="errorSelectOne" class="text-sm text-error">Wajib memilih satu product/service</p>
        </div>
      </div>
      <ResultTableModal 
        :readOnly="readOnly" 
        :heightModal="heightModal" :heightModalTitle="heightModalTitle" :heightModalSave="heightModalSave || 0" @set-height="setHeight"/>
      <div id="modal-save" class="flex justify-end p-6" v-if="(!isSubmitted || isRejected) && !readOnly">
        <ButtonGista 
          @click="clickSave"
          color="success"
          customClass="sm:w-56 w-full"
          >
          Save
        </ButtonGista>
      </div>
    </gista-modal>
  </div>
</template>

<script>
import ResultTable from '@/core/components/rfq/procurementApproval/ResultTable.vue'
import ResultTableModal from '@/core/components/rfq/procurementApproval/ResultTableModal.vue'
import Tiptap from '@/core/components/custom/Tiptap.vue'
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import TiptapView from '@/core/components/custom/TiptapView.vue'
import { deepCloneAdvanced } from '@/core/components/helpers'
import { formatCurrency } from '@/core/components/helpers/formatRupiah'

export default {
  name: "NegotiationResult",
  data(){
    return {
      isOpenMaximize: false,
      heightModal: 0,
      heightModalTitle: 0,
      heightModalSave: 0
    }
  },
  props: ['collapse', 'readOnly'],
  computed:{
    dataProcurementApproval: {
      get() {
        return this.$store.state.procurementApprovalRfq.dataProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_DATA_PROCUREMENT_APPROVAL', value)
      }
    },
    isSubmitted() {
      return this.dataProcurementApproval?.isSubmitted
    },
    logApproval() {
      return this.$store.state.procurementApprovalRfq.logApproval
    },
    isRejected() {
      return this.logApproval?.logDetails?.some(e => e.approvalStatus === 'PROCUREMENT_REJECTED')
    },
    negotiationResult: {
      get() {
        return this.$store.state.procurementApprovalRfq.negotiationResult
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_NEGOTIATION_RESULT', value)
      }
    },
    vendor() {
      return this.dataProcurementApproval.negotiationResult
    },
    errorSelectOne: {
      get() {
        return this.$store.state.procurementApprovalRfq.errorSelectOne
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_ERR_SELECT_ONE', value)
      }
    },
  },
  methods:{
    formatCurrency,
    changeCollapseVendor() {
      this.dataProcurementApproval.negotiationResult.collapse = !this.dataProcurementApproval.negotiationResult?.collapse
    },
    openModal() {
      this.isOpenMaximize = true
      this.negotiationResult = deepCloneAdvanced(this.dataProcurementApproval.negotiationResult)
    },
    closeModal() {
      this.isOpenMaximize = false
    },
    characterCount(e, name) {
      const MAX_CHARACTER = 4500
      if (e > MAX_CHARACTER) this.dataProcurementApproval.negotiationResult.errorMaxLength[name] = `Kolom ${name} Maksimal 4500 Karakter`
      else this.dataProcurementApproval.negotiationResult.errorMaxLength[name] = ''
    },
    async clickSave() {
      this.dataProcurementApproval.negotiationResult = deepCloneAdvanced(this.negotiationResult)
      await this.$store.dispatch('procurementApprovalRfq/getApprovalProcurementPrincipal', { rfqId: this.$route.params.quotationId })
      this.checkErrSelectOne()
      this.closeModal()
    },
    checkErrSelectOne() {
      if(this.errorSelectOne) {
        const data = this.dataProcurementApproval.negotiationResult.negotiationResultDetails.resultItemList
        if(data.some(e => e.isSelected)) this.errorSelectOne = false
      }
    },
    setHeight() {
      this.heightModal = document.getElementById('modal')?.offsetHeight
      this.heightModalTitle = document.getElementById('modal-title')?.offsetHeight
      this.heightModalSave = document.getElementById('modal-save')?.offsetHeight
      window.addEventListener(
        'resize',
        () => {
          this.heightModal = document.getElementById('modal')?.offsetHeight
          this.heightModalTitle = document.getElementById('modal-title')?.offsetHeight
          this.heightModalSave = document.getElementById('modal-save')?.offsetHeight
        }
      )
    },
  },
  components:{
    ResultTable,
    Tiptap,
    TiptapView,
    InputDisabled,
    ResultTableModal
  }
}
</script>