<template>
  <div :class="customClass">
    <ButtonGista 
      @click="changeIsOpen(true)"
      color="success"
      customClass="w-full whitespace-nowrap">
      Submit FPTN
    </ButtonGista>
    <ModalConfirm 
      title="Apakah anda yakin?" 
      message="Pastikan seluruh informasi sudah benar" 
      textButton="Submit FPTN" 
      :isOpen="isOpenFPTN" 
      @changeIsOpen="changeIsOpen($event)" 
      :isBackButton="true"
      @click="clickSubmitFPTN"
      colorButton="success">
    </ModalConfirm>
  </div>
</template>

<script>
import ModalConfirm from '@/core/components/modal/Confirm'

export default {
  name: 'SubmitFPTN',
  props: ['isOpenFPTN', 'customClass'],
  methods: {
    changeIsOpen(val) {
      this.$emit('clickToggleSubmit', val)
    },
    clickSubmitFPTN() {
      this.$emit('click')
    }
  },
  components: {
    ModalConfirm
  }
}
</script>