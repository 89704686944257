<template>
  <div>
    <div v-if="!isLoading">
      <div class="flex gap-3.5 justify-between my-6">
        <div>
          <span class="text-2xl font-bold cursor-pointer icon-arrow-left text-gray"
            @click="clickBackToVendorDetail"></span>
        </div>
        <div>
            <h4 class="text-lg font-medium text-gray-darkest">BAN - {{ banDetails?.currentNegotiationStatus }} - {{ banDetails?.negotiationStatus }}</h4>
        </div>
        <div></div>
      </div>
      <div class="flex flex-col justify-between w-full gap-6 mb-6 sm:flex-row">
        <div class="flex items-center w-full">
          <h5 class="text-base text-gray-darkest">{{ banDetails.vendorName }}</h5>
        </div>
        <div class="w-full sm:w-max">
          <ButtonGista
            @click="clickViewBan"
            size="small"
            type="secondary"
            color="success"
            class="w-full sm:w-max"
            v-if="menuRfqNegotiationBanReport"
          >
            <template #icon-left>
              <span class="icon-eye text-lg pr-3.5"></span>
            </template>
            View BAN
          </ButtonGista>
        </div>
      </div>

      <div v-if="!loading.loadingApprovedBanRfq && !banDetails?.isHasRenegotiation" class="mb-4 overflow-x-auto border rounded-lg border-gray-light">
        <div class="border-gray-light">
          <div class="border-gray-light">
            <div class="p-2">
              <p class="text-sm font-medium text-gray">Negotiation Participant</p>
            </div>
          </div>
          <table class="w-full" aria-describedby="negoParticipant">
            <thead>
              <tr class="bg-primary-lightest">
                <th scope="col" class="p-2 border-t border-gray-light" :class="`${detailBanApprover.representUkkpId && detailBanApprover.representGsitId? 'w-1/3' : 'w-1/2'}`">
                  <p class="text-sm font-medium text-left text-gray">DLOG</p>
                </th>
                <th scope="col" class="p-2 border-t border-l border-gray-light" :class="`${detailBanApprover.representUkkpId? 'w-1/3' : 'w-1/2'}`"  v-if="detailBanApprover.representGsitId">
                  <p class="text-sm font-medium text-left text-gray">GSIT</p>
                </th>
                <th scope="col" class="p-2 border-t border-l border-gray-light" :class="`${detailBanApprover.representGsitId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.representUkkpId" >
                  <p class="text-sm font-medium text-left text-gray">UKKP</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="p-2 border-gray-light" :class="`${detailBanApprover.representUkkpId && detailBanApprover.representGsitId? 'w-1/3' : 'w-1/2'}`">
                  <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{detailBanApprover.itpVerificatorName?.toLowerCase()}}</p>
                  <p v-if="showStatus(detailBanApprover.itpVerificatorStatus)" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailBanApprover.itpVerificatorStatus }} </p>
                  <p v-if="detailBanApprover.itpVerificatorApproved" 
                    class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                    <span :class="approvalColor(detailBanApprover.itpVerificatorApproved)">{{ detailBanApprover.itpVerificatorApproved?.split('|')[0] }}</span>
                    <span class="px-2 font-black text-gray">&#183;</span>
                    <span class="whitespace-nowrap">{{ detailBanApprover.itpVerificatorApproved?.split('|')[1] }}</span> 
                  </p>
                </td>
                <td class="p-2 border-l border-gray-light" :class="`${detailBanApprover.representUkkpId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.representGsitId">
                  <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{detailBanApprover.representGsitName?.toLowerCase()}}</p>
                  <p v-if="showStatus(detailBanApprover.representGsitStatus)" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailBanApprover.representGsitStatus }}</p>
                  <p v-if="detailBanApprover.representGsitApproved" 
                    class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                    <span :class="approvalColor(detailBanApprover.representGsitApproved)">{{ detailBanApprover.representGsitApproved?.split('|')[0] }}</span>
                    <span class="px-2 font-black text-gray">&#183;</span>
                    <span class="whitespace-nowrap">{{ detailBanApprover.representGsitApproved?.split('|')[1] }}</span>
                  </p>
                </td>
                <td class="p-2 border-l border-gray-light" :class="`${detailBanApprover.representGsitId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.representUkkpId">
                  <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{detailBanApprover.representUkkpName?.toLowerCase()}}</p>
                  <p v-if="showStatus(detailBanApprover.representUkkpStatus)" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailBanApprover.representUkkpStatus }}</p>
                  <p v-if="detailBanApprover.representUkkpApproved" 
                    class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                    <span :class="approvalColor(detailBanApprover.representUkkpApproved)">{{ detailBanApprover.representUkkpApproved?.split('|')[0] }}</span>
                    <span class="px-2 font-black text-gray">&#183;</span>
                    <span class="whitespace-nowrap">{{ detailBanApprover.representUkkpApproved?.split('|')[1] }}</span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="!loading.loadingApprovedBanRfq && !banDetails?.isHasRenegotiation" class="mb-4 overflow-x-auto border rounded-lg border-gray-light">
        <div class="border-gray-light">
          <div class="border-gray-light">
            <div class="p-2">
              <p class="text-sm font-medium text-gray">Approved By</p>
            </div>
          </div>
          <table class="w-full" aria-describedby="approvedBy">
            <thead>
              <tr class="bg-primary-lightest" :class="`${detailBanApprover.directApproverUkkpId && detailBanApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`">
                <th scope="col" class="p-2 border-t border-gray-light" :class="`${detailBanApprover.directApproverUkkpId && detailBanApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`">
                  <p class="text-sm font-medium text-left text-gray">DLOG</p>
                </th>
                <th scope="col" class="p-2 border-t border-l border-gray-light" :class="`${detailBanApprover.directApproverUkkpId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.directApproverGsitId">
                  <p class="text-sm font-medium text-left text-gray">GSIT</p>
                </th>
                <th scope="col" class="p-2 border-t border-l border-gray-light" :class="`${detailBanApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.directApproverUkkpId">
                  <p class="text-sm font-medium text-left text-gray">UKKP</p>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
              <td class="p-2 border-gray-light" ::class="`${detailBanApprover.directApproverUkkpId && detailBanApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{detailBanApprover.directApproverItpName?.toLowerCase()}}</p>
                <p v-if="showStatus(detailBanApprover.directApproverItpStatus)" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailBanApprover.directApproverItpStatus }}</p>
                <p v-if="detailBanApprover.directApproverItpApproved" 
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="approvalColor(detailBanApprover.directApproverItpApproved)">{{ detailBanApprover.directApproverItpApproved?.split('|')[0] }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="whitespace-nowrap">{{ detailBanApprover.directApproverItpApproved?.split('|')[1] }}</span>
                </p>
              </td>
              <td class="p-2 border-l border-gray-light" :class="`${detailBanApprover.directApproverUkkpId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.directApproverGsitId">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{detailBanApprover.directApproverGsitName?.toLowerCase()}}</p>
              <p v-if="showStatus(detailBanApprover.directApproverGsitStatus)" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailBanApprover.directApproverGsitStatus }}</p>
              <p v-if="detailBanApprover.directApproverGsitApproved" 
                class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                <span :class="approvalColor(detailBanApprover.directApproverGsitApproved)">{{ detailBanApprover.directApproverGsitApproved?.split('|')[0] }}</span>
                <span class="px-2 font-black text-gray">&#183;</span>
                <span class="whitespace-nowrap">{{ detailBanApprover.directApproverGsitApproved?.split('|')[1] }}</span>
              </p>
              </td>
              <td class="p-2 border-l border-gray-light" :class="`${detailBanApprover.directApproverGsitId? 'w-1/3' : 'w-1/2'}`" v-if="detailBanApprover.directApproverUkkpId">
                <p class="text-sm font-medium capitalize whitespace-nowrap text-gray">{{detailBanApprover.directApproverUkkpName?.toLowerCase()}}</p>
                <p v-if="showStatus(detailBanApprover.directApproverUkkpStatus)" class="mt-1 text-sm font-medium whitespace-nowrap text-warning-dark">{{ detailBanApprover.directApproverUkkpStatus }}</p>
                <p v-if="detailBanApprover.directApproverUkkpApproved" 
                  class="mt-1 text-sm font-medium whitespace-nowrap text-gray-light">
                  <span :class="approvalColor(detailBanApprover.directApproverUkkpApproved)">{{ detailBanApprover.directApproverUkkpApproved?.split('|')[0] }}</span>
                  <span class="px-2 font-black text-gray">&#183;</span>
                  <span class="">{{ detailBanApprover.directApproverUkkpApproved?.split('|')[1] }}</span>
                </p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="loading.loadingApprovedBanRfq">
        <div class="animate-pulse">
          <div class="h-24 mb-4 rounded-lg bg-gray-light"></div>
          <div class="h-24 mb-4 rounded-lg bg-gray-light"></div>
        </div>
      </div>
      <div>
        <div class="flex items-center mb-6">
          <span class="mr-4 icon-list"></span>
          <h5 class="text-lg font-medium text-gray-darkest">Detail BAN</h5>
        </div>
        <div class="grid gap-x-6 gap-y-3.5 mb-3.5 border-b border-gray-lightest pb-3.5"
          :class="{
            'grid-cols-1': quotationDetailContainerWidth < 768,
            'grid-cols-2': quotationDetailContainerWidth >= 768
          }">
          <InputDisabled 
            label="Project ID" 
            :value="banDetails.projectId?banDetails.projectId:'-'"
          />
          <InputDisabled 
            label="Request No."
            :value="banDetails.requestNo?banDetails.requestNo:'-'"
          />
          <InputDisabled 
            label="Vendor Name"
            :value="banDetails.vendorName?banDetails.vendorName:'-'"
          />
          <InputDisabled 
            label="Project Name"
            :value="banDetails.projectName?banDetails.projectName:'-'"
          />
          <InputDisabled 
            label="Request By"
            :value="banDetails.requestByName?banDetails.requestByName?.toLowerCase():'-'"
            class="capitalize"
          />
          <InputDisabled 
            label="Created By"
            :value="banDetails.createdByName?banDetails.createdByName?.toLowerCase():'-'"
            class="capitalize"
          />
          <InputDisabled 
            label="PIC GSIT"
            :value="banDetails.picGsit?banDetails.picGsit?.toLowerCase():'-'"
            class="capitalize"
            v-if="workflowGsit"
          />
          <InputDisabled 
            label="PIC UKKP"
            :value="banDetails.picUkkp?banDetails.picUkkp?.toLowerCase():'-'"
            class="capitalize"
            v-if="!workflowGsit"
          />
          <InputDisabled 
            label="Date Created"
            :value="banDetails.createdAt?banDetails.createdAt:'-'"
          />
          
        </div>
        <div class="mb-6">
          <div class="mb-1">
            <p class="text-sm font-medium text-gray">Project Type </p>
          </div>
          <div class="flex flex-wrap">
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('HARDWARE')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Hardware</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('SOFTWARE')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Software</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('SERVICE')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Service</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('MAINTENANCE')"  class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Maintenance</p>
            </div>
            <div class="flex items-center mb-3">
              <span v-if="banDetails.projectType?.includes('LINK_COMMUNICATION')" class="flex items-center justify-center w-6 h-6 text-xl font-black rounded-md icon-check text-gray-dark bg-primary-lightest"></span>
              <div v-else class="w-6 h-6 rounded-md bg-primary-lightest"></div>
              <p class="ml-2 mr-6 font-medium text-gray">Link-Communication</p>
            </div>
          </div>
        </div>
        <div class="mb-6">
          <NegotiationDealTable :items="banDetails"/>
        </div>
        <div class="grid gap-x-6 gap-y-3.5 pb-3.5"
          :class="{
            'grid-cols-1': quotationDetailContainerWidth < 768,
            'grid-cols-2': quotationDetailContainerWidth >= 768
          }">
          <InputDisabled 
            v-if="banDetails.nameNegotiator1"
            label="Negotiator 1"
            :value="banDetails.nameNegotiator1?.toLowerCase()"
            class="capitalize"
          />
          <InputDisabled 
            v-if="banDetails.nameNegotiator2"
            label="Negotiator 2"
            :value="banDetails.nameNegotiator2?.toLowerCase() ?? '-'"
            class="capitalize"
          />
          <InputDisabled 
            v-if="detailBanApprover.itpVerificatorName"
            label="ITP Verificator (S5-S4)"
            :value="detailBanApprover.itpVerificatorName?.toLowerCase()"
            class="capitalize"
          />
          <InputDisabled 
            v-if="detailBanApprover.directApproverItpName"
            label="Approval ITP (S3-S1)"
            :value="detailBanApprover.directApproverItpName?.toLowerCase()"
            class="capitalize"
          />
          <InputDisabled 
            v-if="detailBanApprover.representGsitName"
            label="Representative GSIT (S3-S2)"
            :value="detailBanApprover.representGsitName?.toLowerCase()"
            class="capitalize"
          />
          <InputDisabled 
            v-if="detailBanApprover.directApproverGsitName"
            label="Approval GSIT (S1)"
            :value="detailBanApprover.directApproverGsitName?.toLowerCase()"
            class="capitalize"
          />
          <InputDisabled 
            v-if="detailBanApprover.representUkkpName"
            label="Representative UKKP (S3-S2)"
            :value="detailBanApprover.representUkkpName?.toLowerCase()"
            class="capitalize"
          />
          <InputDisabled 
            v-if="detailBanApprover.directApproverUkkpName"
            label="Approval UKKP (S1)"
            :value="detailBanApprover.directApproverUkkpName?.toLowerCase()"
            class="capitalize"
          />
        </div>
        
        <div class="grid gap-x-6 gap-y-3.5 pb-3.5"
          :class="{
            'grid-cols-1': quotationDetailContainerWidth < 768,
            'grid-cols-2': quotationDetailContainerWidth >= 768
          }">
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Term of Payment
              </label>
            </div>
            <div class="">
              <TiptapView :value="banDetails.termOfPayment" v-if="banDetails.termOfPayment"/>
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div :class="{
            'col-span-2': quotationDetailContainerWidth < 768,
            'col-span-1': quotationDetailContainerWidth >= 768}"
            >
            <InputDisabled label="Waiver Bg" :value="waiverBgMap[banDetails.isWaiverBg]" />
          </div>
          <div :class="{
            'col-span-2': quotationDetailContainerWidth < 768,
            'col-span-1': quotationDetailContainerWidth >= 768}"
            >
          </div>
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Negotiation Note/ BCA Request 
              </label>
            </div>
            <div class="">
              <TiptapView :value="banDetails.negotiationNote" v-if="banDetails.negotiationNote"/>
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div class="col-span-2">
            <div class="flex items-center">
              <label class="block mb-1 text-sm text-gray">
                Note Internal BCA
              </label>
            </div>
            <div class="">
              <TiptapView :value="banDetails.internalNote" v-if="banDetails.internalNote"/>
              <InputDisabled value="-" v-else/>
            </div>
          </div>
          <div v-for="n,i in fileQuotation" :key="i" class="col-span-2">
            <div>
              <p class="mb-1 text-sm font-medium text-gray">File Quotation</p>
            </div>
            <div class="flex items-center justify-between p-2 border rounded-lg border-primary h-14">
              <div class="text-base text-gray">{{ n.fileName }}</div>
              <p class="text-xl cursor-pointer icon-eye text-primary hover:text-primary-darkest" @click="openFile(n.fileName, n.documentNo)"></p>
            </div>
          </div>
        </div>
        <div class="border border-gray-light rounded-lg p-3.5">
          <div class="flex items-center mb-6">
            <span class="mr-4 icon-list"></span>
            <h5 class="text-lg font-medium text-gray-darkest">Summary Negotiation</h5>
          </div>
          <div class="grid gap-x-6 gap-y-3.5 pb-3.5"
            :class="{
              'grid-cols-1': quotationDetailContainerWidth < 768,
              'grid-cols-2': quotationDetailContainerWidth >= 768
            }">
            <div>
              <InputDisabled 
                label="Initial Price"
                size="medium"
                :value="summaryWithText"
              />
            </div>
            <div>
              <InputDisabled 
                label="Final Price"
                size="medium"
                :value="banDetails.negotiationStatus"
              />
            </div>
          </div>
          <NegotiationTableSummary/>
        </div>
        <div class="pb-6">
          <div class="flex flex-wrap items-center justify-between w-full gap-3 mt-3 mb-6">
            <div class="flex items-center pt-3 pr-4">
              <p class="text-2xl font-bold cursor-pointer icon-list text-gray"></p>
              <h4 class="pl-3 text-lg font-medium">Negotiation details</h4>
            </div>
            <button class="flex items-center" :class="`${disableMaximizeTable? 'cursor-not-allowed': 'cursor-pointer hover:text-gray-dark active:text-gray-darkest'} ${quotationDetailContainerWidth < 640 ? '' : 'pl-4'}`"
                @click="clickToggleMaximizeTable"
                :disabled="disableMaximizeTable"
                >
              <span class="mr-3 text-lg icon-maximize text-gray"></span>
              <p class="text-sm font-semibold text-gray" >Maximize table</p>
            </button>
          </div>
          <MaximizeTable v-if="isOpenMaximizeTable" :isOpen="isOpenMaximizeTable" @clickToggle="clickToggleMaximizeTable"/>
          <NegotiationTableDetails />
        </div>
      </div>
    </div>
    <BanLoading v-else/>
  </div>
</template>

<script>
import TiptapView from '@/core/components/custom/TiptapView.vue'
import NegotiationTableDetails from '@/core/components/rfq/negotiation/NegotiationTableDetails.vue';
import MaximizeTable from '@/core/components/rfq/negotiation/MaximizeTable.vue';
import NegotiationTableSummary from "@/core/components/rfq/negotiation/NegotiationTableSummary.vue";
import InputDisabled from '@/core/components/custom/InputDisabled.vue'
import NegotiationDealTable from "@/core/components/rfq/negotiation/NegotiationDealTable.vue";
import BanLoading from '@/core/components/rfq/loading/BanLoading.vue'
import { CANCELED } from '@/core/constant/statusProject'

export default {
  name: 'DetailBan',
  data() {
    return {
      isOpenMaximizeTable: false,
      time: ['00:00', '00:30', '01:00', '01:30', '02:00', '02:30', '03:00', '03:30', '04:00', '04:30', '05:00', '05:30', '06:00', '06:30', '07:00', '07:30', '08:00', '08:30', '09:00', '09:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00', '18:30', '19:00', '19:30', '20:00', '20:30', '21:00', '21:30', '22:00', '22:30', '23:00', '23:30'],
      waiverBgMap: {
        TANPA_WAIVER: 'No Waiver',
        WAIVER_PERFOMANCE_BOND: 'Performance Bond',
        WAIVER_ADVANCE_PAYMENT_BOND: 'Advance Payment Bond',
        WAIVER_ADVANCE_PAYMENT_BOND_AND_PERFORMANCE_BOND: 'Advance Payment Bond and Performance Bond'
      },
      summaryWithText: '',
      isLoading: false,
    }
  },
  computed: {
    negotiationDetail(){
      return this.$store.state.negotiationRfq.negotiationDetail
    },
    disableMaximizeTable() {
      return this.negotiationDetail?.offers?.length === 0
    },
    banDetails: {
      get() {
        return this.$store.state.negotiationRfq.banDetails
      },
      set(value) {
        this.$store.commit('negotiationRfq/SET_NEGOTIATION_OFFER_BAN', value)
      }
    },
    banNegotiationId() {
      return this.$store.state.negotiationRfq.banNegotiationId
    },
    optionInitialPrice(){
      return this.banDetails.negotiationHistories?.map(e =>({text:e.negotiationStatus, value:e.rfqNegotiationId}))
    },
    detailBanApprover() {
      return this.$store.state.negotiationRfq.detailRFQNegoApprover
    },
    fileQuotation() {
      return this.banDetails?.attachments?.filter(i => i.fileRef === 'fileQuotation')
    },
    workflowGsit() {
      return this.banDetails.isUnitGsit
    },
    quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
    modal: {
      get() {
        return this.$store.state.modal.modal
      },
      set(value) {
        this.$store.commit('modal/SET_MODAL', value)
      }
    },
    loading() {
      return this.$store.state.modal.loading
    },
    menuRfqNegotiationBanReport() {
      return this.$store.getters['dashboard/menuRfqNegotiationBanReport']?.canRead
    },
    counterIntervalApproval() {
			return this.$store.state.negotiationRfq.counterInterval
		},
    projectLogRfq() {
      return this.$store.state.projectLog.projectLogRfq
    },
    sppProcess() {
			return this.$store.state.sppRfq.sppProcess
		},
		someInProgress() {
			return this.projectLogRfq?.rfqLogs?.some(e => e.isInProgress || e.additionalLogs?.some(el => el.isInProgress))
		},
    projectCanceledByLogs() {
			return this.projectLogRfq.rfqLogs.some(e => e.statusProject === CANCELED)
		},
		projectCanceled() {
			return this.sppProcess || this.projectCanceledByLogs
		},
  },
  methods: {
    approvalColor(e) {
      return e?.split('|')[0].includes('Rejected') ? 'text-error' : 'text-success'
    },
    clickBackToVendorDetail() {
      this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_BAN', false)
      this.$router.go(-1)
    },
    async clickViewBan() {
      this.modal.modalLoading = true
      await this.$store.dispatch('negotiationRfq/getBanReport', { negotiationId: this.banDetails.rfqNegotiationId })
      this.modal.modalLoading = false
    },
    clickToggleMaximizeTable() {
      this.isOpenMaximizeTable = !this.isOpenMaximizeTable
    },
    openFile(filename, documentNo) {
      this.$store.dispatch('file/viewAttachment', { filename, documentNo })
    },
    showStatus(status) {
      return status?.toLowerCase().includes('on review') && !this.someInProgress && !this.projectCanceled
    }
  },
  async mounted(){
    this.isLoading = true
		const response = await this.$store.dispatch('negotiationRfq/getNegotiationList', {rfqId:this.$route.params.quotationId})
    const r1 = this.$store.dispatch('negotiationRfq/getNegotiationOfferBan', {negotiationId:this.$route.query.negotiation})
    const r2 = this.$store.dispatch('negotiationRfq/getDetailRFQNegoApprover', { negotiationId: this.$route.query.negotiation })
    const [res1, res2] = await Promise.all([r1, r2])
    const r3 = this.$store.dispatch('negotiationRfq/getSummaryWith', { negotiationId: this.banDetails.summaryRfqNegotiationId ||this.banDetails.negotiationHistories[0].rfqNegotiationId, rfqId: this.negotiationDetail.rfqId })
    const r4 = this.$store.dispatch('negotiationRfq/getNegotiationList',{rfqId:this.$route.params.quotationId})
    const [res3, res4] = await Promise.all([r3, r4])
    if ([res1?.status, res2?.status, res3?.status, res4?.status, response?.status].every(e => e === 200)) {
      this.isLoading = false
      this.summaryWithText = this.banDetails.summaryRfqNegotiationId ? this.optionInitialPrice[this.optionInitialPrice.findIndex(e => e.value === this.banDetails.summaryRfqNegotiationId)]?.text :this.optionInitialPrice[0]?.text
    }
  },
  destroyed() {
    this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_BAN', false)
    clearInterval(this.counterIntervalApproval)
		this.$store.commit('negotiationRfq/SET_COUNTER_INTERVAL', null)
  },
  components: {
    NegotiationTableDetails,
    MaximizeTable,
    NegotiationTableSummary,
    InputDisabled,
    TiptapView,
    NegotiationDealTable,
    BanLoading,
  }

}
</script>

<style scoped>
.min-w-custom {
  min-width: 72rem;
}
.min-w-custom2 {
  min-width: 36rem;
}
</style>