<template>
  <div class="">
    <div class="w-full overflow-auto max-h-custom">
      <div class="w-full">
        <table class="relative w-full border-collapse" id="negotiationTable" aria-describedby="negotiationTable">
          <thead class="sticky top-0 z-20 h-10 py-1 bg-white">
            <tr>
              <th scope="col" class="min-w-12">
                <GistaCheckbox @change="changeCheckBoxAll($event)" :modelValue="allSelected" :disabled="(isSubmitted && !isRejected) || readOnly" class="h-10 px-2 border-b-2 text-gray text-gray-darkest border-primary"></GistaCheckbox>
              </th>
              <th scope="col" class="min-w-12">
                <h5 class="flex items-center h-10 p-2 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">No.</h5>
              </th>
              <th scope="col" class="min-w-64">
                <h5 class="flex items-center h-10 p-2 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Product/Service</h5>
              </th>
              <th scope="col" class="min-w-64">
                <h5 class="flex items-center h-10 p-2 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Sub Product</h5>
              </th>
              <th scope="col" class="min-w-20">
                <h5 class="flex items-center h-10 p-2 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Qty</h5>
              </th>
              <th scope="col" class="min-w-24">
                <h5 class="flex items-center h-10 p-2 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">UoM</h5>
              </th>
              <th scope="col" class="min-w-48">
                <h5 class="flex items-center h-10 p-2 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Initial Price ({{currency}})</h5>
              </th>
              <th scope="col" class="min-w-48">
                <h5 class="flex items-center h-10 p-2 text-sm font-medium text-left border-b-2 text-gray-darkest border-primary">Final Price ({{currency}})</h5>
              </th>
            </tr>
          </thead>
          <tbody >
            <template>
              <tr v-for="item, i in data?.resultItemList" :key="i" class="h-10" :class="i % 2 === 0 ? 'bg-white' : 'bg-primary-lightest bg-opacity-25'">
                  <td class="align-top">
                    <GistaCheckbox @change="changeCheckBoxItem($event, i)" :modelValue="item.isSelected" :disabled="(isSubmitted && !isRejected) || readOnly" class="px-2 text-gray"></GistaCheckbox>
                  </td>
                  <td class="align-top">
                    <h5 class="p-2 text-sm text-gray-darkest">{{i+1}}</h5>
                  </td>
                  <td class="align-top">
                    <h5 v-if="item.productService?.length > 34" class="p-2 text-sm break-words whitespace-pre-wrap text-gray-darkest">{{item.productService.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(item.productService)">Read More</span></h5>
										<h5 v-else class="p-2 text-sm break-words whitespace-pre-wrap text-gray-darkest">{{item.productService}}</h5>
                  </td>
                  <td class="align-top">
                    <h5 v-if="item.subProduct?.length > 34" class="p-2 text-sm break-words whitespace-pre-wrap text-gray-darkest">{{item.subProduct.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(item.subProduct)">Read More</span></h5>
										<h5 v-else class="p-2 text-sm break-words whitespace-pre-wrap text-gray-darkest">{{item.subProduct}}</h5>
                  </td>
                  <td class="align-top">
                    <h5 class="p-2 text-sm text-right text-gray-darkest">{{ item.qty }}</h5>
                  </td>
                  <td class="align-top">
                    <h5 v-if="item.uom?.length > 34" class="p-2 text-sm break-words whitespace-pre-wrap text-gray-darkest">{{item.uom.slice(0,20)}}... <span class="cursor-pointer text-secondary" @click="readMore(item.uom)">Read More</span></h5>
										<h5 v-else class="p-2 text-sm break-words whitespace-pre-wrap text-gray-darkest">{{item.uom}}</h5>
                  </td>
                  <td class="align-top">
                    <h5 class="p-2 text-sm text-right text-gray-darkest">{{ formatCurrency(item.initialPrice, currency, null, 0) }}</h5>
                  </td>
                  <td class="align-top">
                    <h5 class="p-2 text-sm text-right text-gray-darkest">{{ formatCurrency(item.finalPrice, currency, null, 0) }}</h5>
                  </td>
                </tr>
            </template>
          </tbody>
          <tbody class="sticky bottom-0 bg-gradient-to-t from-primary-lightest to-white">
            <tr>
              <td></td>
              <td class="p-2"><span class="text-base icon-corner-down-right"></span></td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b border-primary">
                  <div class="flex">
                    <p class="flex-auto w-56 p-2 text-sm font-medium text-gray-darkest">Total Price (Initial Price)</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">{{ formatCurrency(data.initialPrice, currency, null, 0) }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b border-primary">
                  <div class="flex">
                    <p class="flex-auto w-56 p-2 text-sm font-medium text-gray-darkest">Total Price (Final Price)</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">{{formatCurrency(data.finalPrice, currency, null, 0)}}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b border-primary">
                  <div class="flex">
                    <p class="flex-auto w-56 p-2 text-sm font-medium text-gray-darkest">VAT (%)</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">{{ data.vat }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b border-primary">
                  <div class="flex">
                    <p class="flex-auto w-56 p-2 text-sm font-medium text-gray-darkest">VAT Amount</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">{{ formatCurrency(data.vatAmount, currency, null, 0) }}</p>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2"></td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b border-primary">
                  <div class="flex">
                    <p class="flex-auto w-56 p-2 text-sm font-medium text-gray-darkest">Currency</p>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">{{ data.currency }}</p>
                </div>
              </td>
            </tr>
            <tr v-if="currency?.toLowerCase() !== EXCHANGE_RATE_CURRENCY">
              <td colspan="2"></td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b border-primary">
                  <div class="flex items-center">
                    <h5 class="flex-auto w-56 p-2 text-sm font-medium text-gray-darkest">Exchange Rate <span v-if="(!isSubmitted || isRejected) && !readOnly" class="text-sm font-semibold text-error">*</span></h5>
                    <p class="flex-1 text-sm font-medium text-primary-darkest">:</p>
                  </div>
                  <div v-if="(!isSubmitted || isRejected) && !readOnly" class="pl-4">
                    <InputBorderB 
                      v-model="data.exchangeRate" 
                      @keypress="validationInputExchangeRate" 
                      @blur="blurExchangeRate" 
                      @input="inputExchangeRate" 
                      @handleInput="handleInputExchangeRate" 
                      :placeholder="'0'"
                      :bgTransp="true" 
                      textAlign="left"
                      :error="!!negotiationResult.errorMaxLength?.['Exchange Rate']">
                      <template #message>
                        {{ negotiationResult.errorMaxLength?.['Exchange Rate'] }}
                      </template>
                    </InputBorderB>
                  </div>
                  <div v-else>
                    <p class="pl-4 text-sm font-medium text-primary-darkest">{{data.exchangeRate}}</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr v-if="currency?.toLowerCase() !== EXCHANGE_RATE_CURRENCY">
              <td colspan="2"></td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b border-primary">
                  <div class="flex">
                    <h5 class="flex-auto w-56 p-2 text-sm font-medium text-gray-darkest">Subtotal</h5>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <div>
                    <p class="pl-4 text-sm font-medium text-primary-darkest">{{ formatCurrency(data.subTotal, currency, null, 0) }}</p>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td colspan="2" >
                <div class="h-10 border-b-2 border-primary"></div>
              </td>
              <td colspan="7">
                <div class="flex items-center h-10 border-b-2 border-primary">
                  <div class="flex">
                    <h5 class="flex-auto w-56 p-2 text-sm font-medium text-gray-darkest">Grand Total in IDR:</h5>
                    <p class="flex-1 text-sm font-medium text-gray-darkest">:</p>
                  </div>
                  <p class="pl-4 text-sm font-medium text-primary-darkest">{{ formatCurrency(data.grandTotalIdr, EXCHANGE_RATE_CURRENCY, null, 0) }}</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
				
			</div>
		</div>
    <ModalReadMore @click="closeModal" :isOpen="readMoreShow" :text="notes" />
	</div>
</template>

<script>
import InputBorderB from '@/core/components/rfq/procurementApproval/InputBorderB.vue'
import { formatCurrency } from '@/core/components/helpers/formatRupiah'
import { vatIncludedChecker } from '@/core/components/helpers'
import { formatThousandSeparate, parseLocaleNumber } from '@/core/components/helpers/formatRupiah'
import ModalReadMore from '@/core/components/modal/ReadMore.vue'
import { MESSAGE_EMPTY_FIELD } from '@/core/constant/errorMessage.js'
import { EXCHANGE_RATE_CURRENCY } from '@/core/constant/procurementApproval.js'

export default {
  name: 'ResultTable',
  props: ['readOnly'],
  data() {
    return {
      allSelected: false,
      readMoreShow: false,
			notes: '',
      EXCHANGE_RATE_CURRENCY
    }
  },
  computed: {
    dataProcurementApproval: {
      get() {
        return this.$store.state.procurementApprovalRfq.dataProcurementApproval
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_DATA_PROCUREMENT_APPROVAL', value)
      }
    },
    isSubmitted() {
      return this.dataProcurementApproval?.isSubmitted
    },
    logApproval() {
      return this.$store.state.procurementApprovalRfq.logApproval
    },
    isRejected() {
      return this.logApproval?.logDetails?.some(e => e.approvalStatus === 'PROCUREMENT_REJECTED')
    },
    data() {
      return this.dataProcurementApproval.negotiationResult.negotiationResultDetails
    },
    negotiationResult() {
      return this.dataProcurementApproval.negotiationResult 
    },
    currency() {
      return this.dataProcurementApproval.negotiationResult.negotiationResultDetails?.currency
    },
    errorSelectOne: {
      get() {
        return this.$store.state.procurementApprovalRfq.errorSelectOne
      },
      set(value) {
        this.$store.commit('procurementApprovalRfq/SET_ERR_SELECT_ONE', value)
      }
    },
  },
  methods: {
    formatCurrency,
    formatThousandSeparate,
    vatIncludedChecker,
    async getApprovalProcurement() {
      await this.$store.dispatch('procurementApprovalRfq/getApprovalProcurementPrincipal', { rfqId: this.$route.params.quotationId })
    },
    checkAllSelected() {
      this.allSelected = this.dataProcurementApproval.negotiationResult.negotiationResultDetails.resultItemList?.every(e => e.isSelected)
    },
    changeCheckBoxAll(e) {
      this.dataProcurementApproval.negotiationResult.negotiationResultDetails.resultItemList = this.dataProcurementApproval.negotiationResult.negotiationResultDetails.resultItemList?.map(el => ({ ...el, isSelected: e }))
      this.getApprovalProcurement()
      this.checkErrSelectOne(e)
    },
    changeCheckBoxItem(e, i) {
      this.dataProcurementApproval.negotiationResult.negotiationResultDetails.resultItemList[i].isSelected = e
      this.checkAllSelected()
      this.getApprovalProcurement()
      this.checkErrSelectOne(e)
    },
    checkErrSelectOne(e) {
      if(this.errorSelectOne && e) this.errorSelectOne = false
    },
    handleCheckLength(e, name) {
      if(e?.length > 13) this.dataProcurementApproval.negotiationResult.errorMaxLength[name] = 'Maksimal 10 digit'
      else if(e?.length === 0) this.dataProcurementApproval.negotiationResult.errorMaxLength[name] = MESSAGE_EMPTY_FIELD
      else this.dataProcurementApproval.negotiationResult.errorMaxLength[name] = ''
    },
    validationInputExchangeRate(e) {
      let keyCode = e.keyCode
			if ((keyCode > 47 && keyCode < 58)) {
				return
			}
			e.preventDefault();
    },
    inputExchangeRate(e) {
      this.dataProcurementApproval.negotiationResult.negotiationResultDetails.exchangeRate = formatThousandSeparate(parseLocaleNumber(e, EXCHANGE_RATE_CURRENCY), EXCHANGE_RATE_CURRENCY)
      this.handleCheckLength(e, 'Exchange Rate')
    },
    handleInputExchangeRate(e) {
      const el = e.target;
			const formatted = formatThousandSeparate(parseLocaleNumber(el.value, EXCHANGE_RATE_CURRENCY), EXCHANGE_RATE_CURRENCY)
			const count = formatted?.split('.').length
			const count2 = el.value?.split('.').length
			let sel = count > count2 ? el.selectionStart + 1 : count < count2 ? el.selectionStart - 1 : el.selectionStart
      this.$nextTick(() => {
        el.setSelectionRange(sel, sel);
      });
		},
    blurExchangeRate() {
      this.getApprovalProcurement()
    },
    readMore(notes) {
			this.notes = notes
			this.readMoreShow = true
		},
    closeModal() {
			this.readMoreShow = false
		},
  },
  mounted() {
    this.checkAllSelected()
  },
  components: {
    InputBorderB,
    ModalReadMore
  }
}
</script>

<style scoped>
.max-h-custom {
	max-height: 50rem;
}
.min-w-12 {
	min-width: 3rem;
}
.min-w-20 {
  min-width: 5rem;
}
.min-w-24 {
  min-width: 6rem;
}
.min-w-48 {
	min-width: 12rem;
}
.min-w-64 {
	min-width: 16rem;
}
#negotiationTable {
	border-collapse: collapse;
}
#negotiationTable th {
	padding: 0; 
	margin: 0;
}

</style>
