<template>
  <div :class="customClass">
    <ButtonGista 
      type="success" 
      color="success"
      @click="changeIsOpen(true)" 
      :class="customClass"
      :disabled="disabled"
      >Continue Draft
    </ButtonGista>
    <ModalConfirm 
      title="Lanjutkan Draft?" 
      :message="`Draft terakhir: ${ negotiationDetail.lastBanFptnDealModifiedDate }`" 
      icon="saved" textButton="Continue Draft" 
      :isOpen="isOpenFptnContinueDraft" 
      @changeIsOpen="changeIsOpen($event)" 
      @click="clickContinueDraft"
      colorButton="success">
    </ModalConfirm>
  </div>
</template>

<script>
import ModalConfirm from '@/core/components/modal/Confirm'

  export default {
    name: 'FptnContinueDraft',
    props: ['isOpenFptnContinueDraft', 'customClass', 'disabled'],

    computed: {
			negotiationDetail(){
				return this.$store.state.negotiationRfq.negotiationDetail
			},
    },
    methods: {
      changeIsOpen(val) {
        this.$emit('clickToggleFptn', val)
      },
      clickContinueDraft() {
        this.$emit('click')
        this.changeIsOpen(false)
      }
    },
    components: {
      ModalConfirm
    }

  }
</script>