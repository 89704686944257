<template>
	<MainLayout>
		<LayoutQuotationDetail :key="$route.params.quotationId">
			<DetailQuotation v-if="activePage === QUOTATION_DETAILS" />
			<NegotiationQuotation v-if="activePage === QUOTATION_NEGOTIATION" />
			<ProcurementApproval v-if="activePage === QUOTATION_APPROVAL" />
			<Loi v-if="activePage === QUOTATION_LOI" />
			<Spp v-if="activePage === QUOTATION_SPP" />
		</LayoutQuotationDetail>


	</MainLayout>
</template>
<script>
import LayoutQuotationDetail from '@/core/layouts/LayoutQuotationDetail.vue';
import DetailQuotation from '@/core/components/rfq/DetailQuotation.vue';
import NegotiationQuotation from '@/core/components/rfq/NegotiationQuotation.vue';
import ProcurementApproval from '@/core/components/rfq/ProcurementApproval.vue'
import Loi from '@/core/components/rfq/Loi.vue'
import Spp from '@/core/components/rfq/Spp.vue'

import { QUOTATION_DETAILS, QUOTATION_NEGOTIATION, QUOTATION_APPROVAL, QUOTATION_LOI, QUOTATION_SPP } from '@/core/constant/routeName'


export default {
	name: "QuotationDetails",
	data() {
		return {
			QUOTATION_DETAILS, 
			QUOTATION_NEGOTIATION, 
			QUOTATION_APPROVAL,
			QUOTATION_LOI,
			QUOTATION_SPP
		}
	},
	computed: {
		activePage() {
			return this.$store.state.quotationDetail.activePage
		}
	},
	async mounted() {
		await this.$store.dispatch('sppRfq/getSpp', { rfqId: this.$route.params.quotationId })
	},
	components: {
		LayoutQuotationDetail,
		DetailQuotation,
		NegotiationQuotation,
		ProcurementApproval,
		Loi,
		Spp
	},
}
</script>
