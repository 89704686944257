<template>
	<div>
		<div class="flex flex-wrap justify-between pb-4 mt-6 border-b border-gray-lightest" :class="quotationDetailContainerWidth < 640? 'flex-col': 'flex-row'" >
			<div class="flex items-center justify-start " :class="quotationDetailContainerWidth < 640? 'flex-col': 'flex-row pr-4 gap-5'">
				<h5 class="text-lg font-bold text-gray-darkest">{{ negotiationDetail.vendorName }}</h5>
				<div v-if="negotiationDetail.isNewVendor === true" class="px-2 py-1 mb-1 rounded-md bg-success-lightest">
					<label class="text-sm text-success-dark">New Vendor</label>
				</div>
			</div>
      <div class="flex flex-wrap" :class="quotationDetailContainerWidth < 640 ? 'flex-col gap-3' : 'gap-6'">
        <div class="flex items-center justify-start">
					<div v-if="negotiationDetail?.isNegotiationRequired !== false && negotiationDetail.offers?.length > 0"
						class="border-gray-lightest" :class="{'pl-6 border-l': quotationDetailContainerWidth > 640}">
						<div v-if="negotiationDetail?.isNegotiationRequired !== false">
							<label class="text-sm text-gray">Negotiation status</label>
							<div v-if="negotiationDetail?.isNegotiationRequired !== null " class="w-max mt-2.5 flex items-center rounded-md py-2 px-2.5" :class="negotiationStatusBg(negotiationDetail.negotiationStatus)">
								<span class="mr-3 text-2xl cursor-pointer icon-tag" :class="negotiationStatusTextColor(negotiationDetail.negotiationStatus)"></span>
								<h5 class="text-base" :class="negotiationStatusTextColor(negotiationDetail.negotiationStatus)">{{ negotiationStatusText(negotiationDetail.negotiationStatus)}}</h5>
							</div>
							<div v-else class="w-max mt-2.5 flex items-center rounded-md py-2 px-2.5 bg-warning-lightest">
								<span class="mr-3 text-2xl cursor-pointer icon-tag text-warning-dark font-base"></span>
								<h5 class="text-base text-warning-dark">Waiting</h5>
							</div>
						</div>
					</div>
					
					<div v-else-if="negotiationDetail?.isNegotiationRequired === null"
					class="border-gray-lightest" :class="{'pl-6 border-l': quotationDetailContainerWidth > 640}">
						<label class="text-sm text-gray">Negotiation status</label>
						<div class="w-max mt-2.5 flex items-center rounded-md py-2 px-2.5  bg-gray-lightest">
							<span class="mr-3 text-2xl cursor-pointer icon-tag text-gray-darkest font-regular"></span>
							<h5 class="text-base text-gray-darkest">No Initial Offer</h5>
						</div>
					</div>
				</div>

				<div v-if="negotiationDetail?.logApprovalStatus" class="border-gray-lightest" :class="{'pl-6 border-l': quotationDetailContainerWidth > 640}">
					<label class="text-sm text-gray">{{negotiationDetail?.logApprovalTitle}}</label>
					<div class="w-max mt-2.5 flex items-center rounded-md py-2 px-2.5" :class="logApprovalStatusBg(negotiationDetail?.logApprovalStatus)">
						<span class="mr-3 text-2xl" :class="logApprovalStatusIcon(negotiationDetail?.logApprovalStatus)"></span>
						<h5 class="text-base" :class="logApprovalStatusText(negotiationDetail?.logApprovalStatus)">{{ negotiationDetail?.logApprovalStatus}}</h5>
					</div>
				</div>
				<div v-if="negotiationDetail?.statusApprovalITP1Verification" class="border-gray-lightest" :class="{'pl-6 border-l': quotationDetailContainerWidth > 640}">
					<label class="text-sm text-gray">ITP 1 Verification</label>
					<div class="w-max mt-2.5 flex items-center rounded-md py-2 px-2.5 bg-warning-lightest">
						<span class="mr-3 text-2xl cursor-pointer icon-check text-warning"></span>
						<h5  class="text-base text-warning">{{ negotiationDetail.statusApprovalITP1Verification }}</h5>
					</div>
				</div>
      </div>
		</div>
		<div v-if="negotiationDetail?.isNegotiationScheduled !== true && !negotiationDetail.isEnableContinueDraftBanFptnDeal && !checkOffers">
			<div v-if="checkRole && negotiationRequired !== null && isChangeRequired === false && negotiationDetail.logApprovalStatus ===null" class="py-4 border-b border-gray-lightest">
				<div class="flex justify-between">
					<div class="flex items-center">
						<span class="pr-3 font-medium icon-check-circle text-gray"></span>
						<h2 v-if="negotiationRequired ===true" class="font-medium text-gray">Negotiation is Required</h2>
						<h2 v-else class="font-medium text-gray">Negotiation is not Required</h2>
					</div>
					<div>
						<p class="text-sm font-medium underline cursor-pointer text-secondary" @click="toggleChangeRequired">Change</p>
					</div>
				</div>
			</div>
		</div>
		<div class="w-full">
			<div class="flex gap-3.5 items-center justify-between w-full mt-3 mb-6 title" :class="`${quotationDetailContainerWidth < 768? 'flex-col' : 'flex-row'}`">
				<div class="flex items-center w-full" :class="{'w-max': quotationDetailContainerWidth > 768 }">
					<p class="text-2xl font-bold cursor-pointer icon-list text-gray"></p>
					<h4 v-if="negotiationDetail.isNegotiationRequired ===true" class="pl-3 text-lg font-medium">Negotiation Details</h4>
					<h4 v-else class="pl-3 text-lg font-medium">Current Procurement Cost</h4>
				</div>
				<div class="flex items-center justify-center w-full gap-4" :class="`${quotationDetailContainerWidth < 640? 'flex-col' : 'flex-row gap-x-6 justify-between'} ${quotationDetailContainerWidth < 768? '' : 'w-max'}`" >
					<div v-if="menuRfqNegotiationOfferUpload && !sppProcess" class="w-full" :class="{'w-max': quotationDetailContainerWidth > 640}">
						<button-gista
							size="small"
							@click="clickUploadOffer"
							:disabled="!negotiationDetail.isEnableUploadOffer || negotiationDetail?.isCanceled"
							class="w-full"
							>
							<template #icon-left>
								<span class="mr-3 text-2xl icon-file-plus"></span>
							</template>
							Upload Offer
						</button-gista>
					</div>
					<UploadOffer v-if="modalUploadOffer"/>
					<EditOffer v-if="showEditOffer !== false"/>
					<div class="flex justify-center w-full" :class="{ 'w-max': quotationDetailContainerWidth > 640 }">
						<button class="flex items-center" :class="`${disableMaximizeTable? 'cursor-not-allowed': 'cursor-pointer hover:text-gray-dark active:text-gray-darkest'}`"
								@click="clickToggleMaximizeTable"
								:disabled="disableMaximizeTable"
								>
							<span class="mr-3 text-lg icon-maximize text-gray"></span>
							<p class="text-sm font-semibold text-gray" >Maximize table</p>
						</button>
					</div>
				</div>
				<MaximizeTable v-if="isOpenMaximizeTable" :isOpen="isOpenMaximizeTable" @clickToggle="clickToggleMaximizeTable"/>
			</div>
			<NegotiationTable @clickEdit="() => negotiationRequired ? clickEditBan() : clickEditFptn()" @clickContDraft="() => negotiationRequired ? changeIsOpenBanDealContinueDraft(true) : changeIsOpenContinueDraft(true)" @clickRevise="clickRevise" @reviseDraft="setContinueDraftData" />
			<!-- action RENEGOTIATION start -->
			<div v-if="negotiationDetail?.isEnableRenegotiation && !sppProcess" :class="quotationDetailContainerWidth < 640 ? 'flex-col gap-3' : ''" class="flex items-center justify-end w-full mt-6">
				<ReNegotiation 
					@click="clickReNegotiation"
					@clickToggle="clickToggleIsOpenReNegotiation($event)" 
					:isOpen="isOpenReNegotiation"
					v-if="menuRfqNegotiationRenegotiation"
					:disabled="negotiationDetail?.isCanceled"
					:class="quotationDetailContainerWidth < 640 ? 'w-full' : 'mr-3.5'"
				/>
				<ButtonGista
					color="secondary"
					@click="clickEditBan"
					:class="quotationDetailContainerWidth < 640 ? 'w-full' : ''"
					:disabled="negotiationDetail?.isCanceled"
					v-if="negotiationRequired && menuRfqNegotiationOfferDeal && negotiationDetail.isEnableContinueDraftBanFptnDeal !== true"
				>
					Continue BAN
				</ButtonGista>
				<BanDealContinueDraft
					v-if="negotiationRequired && menuRfqNegotiationOfferDeal && negotiationDetail.isEnableContinueDraftBanFptnDeal === true"
					:isOpenBanDealContinueDraft="isOpenBanDealContinueDraft"
					@clickToggleDeal="changeIsOpenBanDealContinueDraft"
					@click="clickEditBan"
					:disabled="negotiationDetail?.isCanceled"
				/>
				<ButtonGista
					color="secondary"
					@click="clickEditFptn"
					:class="quotationDetailContainerWidth < 640 ? 'w-full' : ''"
					:disabled="negotiationDetail?.isCanceled"
					v-if="!negotiationRequired && negotiationDetail.isEnableContinueDraftBanFptnDeal !== true && menuRfqNegotiationFptnSubmit"
				>
					Continue FPTN
				</ButtonGista>
				<FptnContinueDraft
					v-if="!negotiationRequired && negotiationDetail.isEnableContinueDraftBanFptnDeal === true && menuRfqNegotiationFptnSubmit"
					:isOpenFptnContinueDraft="isOpenFptnContinueDraft"
					@clickToggleFptn="changeIsOpenContinueDraft($event)"
					@click="clickEditFptn"
					:disabled="negotiationDetail?.isCanceled"
					:customClass="quotationDetailContainerWidth < 640 ? 'w-full' : ''"
				/>
			</div>
			<!-- action RENEGOTIATION end -->
			<div>
				<div class="flex flex-wrap items-center justify-between w-full gap-6 mt-6" v-if="negotiationStatusText(negotiationDetail.negotiationStatus) !== 'Final Deal' && isChangeRequired === false && !sppProcess">
					<div v-if="negotiationRequired !== null && negotiationRequired === true" class="flex items-center justify-between w-full gap-6 mt-6" :class="quotationDetailContainerWidth < 640? 'flex-col w-full' : 'flex-row'">
						<div class="flex flex-wrap" :class="quotationDetailContainerWidth < 640? 'w-full' : ''" >
							<NegotiationScheduled v-if="menuRfqNegotiationScheduleConfirm && !negotiationDetail.isNegotiationScheduled" />
							
							<div v-if="negotiationDetail?.isEnableScheduleReminderNotes" class="flex py-4">
								<p class="mr-2 text-lg font-medium icon-info text-warning"></p>
								<p class="text-sm font-medium text-warning">it's been 7 days, are negotiations scheduled?</p>
							</div>
							<div v-if="menuRfqNegotiationScheduleConfirm && negotiationDetail.isNegotiationScheduled"  class="flex items-center mr-3">
								<span class="mr-3 text-2xl font-bold icon-check text-secondary"></span>
								<p class="text-sm font-bold text-center text-secondary">Negotiation Scheduled</p>
							</div>
						</div>
						<!-- action BAN start -->
						<div class="flex gap-4 justify-content-end" :class="quotationDetailContainerWidth < 640? 'flex-col w-full' : 'flex-row'">
							
							<ButtonGista 
								v-if="menuRfqNegotiationOfferRevision && negotiationDetail.isEnableContinueDraftBanFptnReviseOffer !== true"
								@click="clickRevise"
								type="primary"
								color="error"
								:customClass="quotationDetailContainerWidth < 640? 'w-full': 'w-max'" 
								:disabled="!negotiationDetail.isNegotiationScheduled || negotiationDetail.isEnableUploadOffer === true || negotiationDetail?.isCanceled"
								>
								Revise Offer
							</ButtonGista>
						
							<BanContinueDraft
								v-if="menuRfqNegotiationOfferRevision && negotiationDetail.isEnableContinueDraftBanFptnReviseOffer === true && negotiationDetail.isEnableUploadOffer === false"
								:isOpenBanContinueDraft="isOpenBanContinueDraft"
								@clickToggle="changeIsOpenBanContinueDraft($event)"
								@click="clickRevise"
								:disabled="negotiationDetail?.isCanceled"
							/>
							
							<ButtonGista color="success"
								v-if="menuRfqNegotiationOfferDeal && negotiationDetail.isEnableContinueDraftBanFptnDeal !== true" 
								@click="clickDeal" 
								:disabled="!negotiationDetail.isEnableDeal || negotiationDetail?.isCanceled"
								:customClass="quotationDetailContainerWidth < 640? 'w-full': 'w-max'"
								>
								Deal
							</ButtonGista>
							<BanDealContinueDraft
								v-if="menuRfqNegotiationOfferDeal && negotiationDetail.isEnableContinueDraftBanFptnDeal === true"
								:isOpenBanDealContinueDraft="isOpenBanDealContinueDraft"
								@clickToggleDeal="changeIsOpenBanDealContinueDraft"
								@click="clickDeal" 
								:disabled="negotiationDetail?.isCanceled"
							/>
						</div>
						<!-- action BAN End -->
					</div>
					<!-- action BAN FPTN start -->
					<div
						v-if="negotiationRequired === false" 
						class="flex items-center justify-end w-full">
					
						<div class="flex justify-end w-full mt-6"  :class="{'w-max': quotationDetailContainerWidth > 640}">
							<FptnContinueDraft
								v-if="menuRfqNegotiationFptnSubmit && negotiationDetail.isEnableContinueDraftBanFptnDeal === true"
								:isOpenFptnContinueDraft="isOpenFptnContinueDraft"
								@clickToggleFptn="changeIsOpenContinueDraft($event)"
								@click="clickFPTN"
								:disabled="negotiationDetail?.isCanceled"
								/>
							<ButtonGista
								v-if="menuRfqNegotiationFptnSubmit && negotiationDetail.isEnableContinueDraftBanFptnDeal === false && negotiationDetail.logApprovalStatus === null"
								color="success"
								@click="clickFPTN"
								:disabled="!negotiationDetail.offers[0] || negotiationDetail?.isCanceled"
								:customClass="quotationDetailContainerWidth < 640? 'w-full': 'w-max'"
								>FPTN
							</ButtonGista>
						</div>
							
					</div>
					<!-- action BAN FPTN end -->
				</div>
			</div>
			<div>
				<div class="w-full test" v-if="negotiationDetail.offers?.length > 0 && !sppProcess" >
					<NegotiationRequired v-if="(negotiationRequired === null || isChangeRequired === true) && checkRole" @changeNegotiationRequired="changeNegotiationRequired" :disabled="negotiationDetail?.isCanceled"/>
				</div>
			</div>
		</div>
		<ModalConfirm 
			title="Lanjutkan Draft?" 
			:message="`Draft terakhir: ${ continueDraftData }`" 
			icon="saved" textButton="Continue Draft" 
			:isOpen="!!continueDraftData" 
			@changeIsOpen="setContinueDraftData($event)" 
			@click="clickRevise"
			colorButton="success">
		</ModalConfirm>
	</div>
</template>

<script>
	import NegotiationTable from '@/core/components/rfq/negotiation/NegotiationTable.vue';
	import NegotiationScheduled from '@/core/components/rfq/negotiation/NegotiationScheduled.vue';
	import MaximizeTable from '@/core/components/rfq/negotiation/MaximizeTable.vue';
	import NegotiationRequired from '@/core/components/rfq/negotiation/NegotiationRequired.vue';
	import UploadOffer from '@/core/components/rfq/negotiation/UploadOffer.vue';
	import EditOffer from '@/core/components/rfq/negotiation/EditOffer.vue';
	import FptnContinueDraft from '@/core/components/modal/negotiation/FptnContinueDraft.vue';
	import BanContinueDraft from '@/core/components/modal/negotiation/BanContinueDraft.vue';
	import BanDealContinueDraft from '@/core/components/modal/negotiation/BanDealContinueDraft.vue';
	import ReNegotiation from '@/core/components/modal/negotiation/ReNegotiation.vue';
	import ModalConfirm from '@/core/components/modal/Confirm'

	export default {
		name: "VendorDetail",
		data() {
			return {
				collapse: {
					negotiationNote: false,
				},
				popUpNegotiationNote: false,
      	isOpenMaximizeTable: false,
				fileOffer: {},
				isOpenFptnContinueDraft: false,
				isOpenBanContinueDraft: false,
				isOpenBanDealContinueDraft: false,
				isOpenReNegotiation: false,
				isChangeRequired: false,
				continueDraftData: false
			}
		},
		computed: {
			negotiationDetail: {
				get() {
					return this.$store.state.negotiationRfq.negotiationDetail
				},
				set(val) {
					this.$store.commit('negotiationRfq/SET_NEGOTIATION_DETAIL', val)
				}
			},
			disableMaximizeTable() {
				return this.negotiationDetail?.offers?.length === 0
			},
			modalUploadOffer(){
				return this.$store.state.negotiationRfq.modalUploadOffer
			},
			negotiationRequired() {
				return this.negotiationDetail.isNegotiationRequired
			},
			quotationDetailContainerWidth() {
				return this.$store.state.width.quotationDetailContainerWidth
			},
			checkRole() {
				return this.$store.getters['dashboard/menuRfqNegotiationScheduleRequire']?.canUpdate
			},
			menuRfqNegotiationScheduleConfirm() {
				return this.$store.getters['dashboard/menuRfqNegotiationScheduleConfirm']?.canUpdate
			},
			menuRfqNegotiationOfferRevision() {
				return this.$store.getters['dashboard/menuRfqNegotiationOfferRevision']?.canCreate
			},
			vendorDetail: {
				get() {
					return this.$store.state.negotiation.vendorDetail
				},
				set(value) {
					this.$store.commit('negotiation/SET_VENDOR_DETAIL', value)
				},
				
			},
			showEditOffer(){
				return this.$store.state.negotiationRfq.showEditOffer
			},
			menuRfqNegotiationRenegotiation() {
				return this.$store.getters['dashboard/menuRfqNegotiationRenegotiation']?.canUpdate
			},
			menuRfqNegotiationFptnSubmit() {
				return this.$store.getters['dashboard/menuRfqNegotiationFptnSubmit']?.canCreate
			},
			menuRfqNegotiationOfferDeal() {
				return this.$store.getters['dashboard/menuRfqNegotiationOfferDeal']?.canUpdate
			},
			menuRfqNegotiationOfferUpload() {
				return this.$store.getters['dashboard/menuRfqNegotiationOfferUpload']?.canCreate
			},
			modal: {
				get() {
					return this.$store.state.modal.modal
				},
				set(value) {
					this.$store.commit('modal/SET_MODAL', value)
				}
			},
			checkOffers() {
				const offers = this.negotiationDetail?.offers
				if(offers?.length > 0) {
					return offers[offers?.length - 1]?.isBanFptnSubmitted
				}
				return false
			},
			counterInterval() {
				return this.$store.state.projectLog.counterInterval
			},
			sppProcess() {
				return this.$store.state.sppRfq.sppProcess
			},
		},

		methods: {
			toggleChangeRequired(){
				this.isChangeRequired = !this.isChangeRequired
			},
			changeIsOpenContinueDraft(val) {
				this.isOpenFptnContinueDraft = val
			},
			changeIsOpenBanContinueDraft(val){
				this.isOpenBanContinueDraft = val
			},
			setContinueDraftData(val) {
				this.continueDraftData = val
			},
			changeIsOpenBanDealContinueDraft(){
				this.isOpenBanDealContinueDraft = !this.isOpenBanDealContinueDraft
			},
			clickRevise() {
				setTimeout(() => {
					this.$store.commit('negotiationRfq/SET_SHOW_REVISE_OFFER', true)
				}, 300)
			},
			
			clickDeal() {
				setTimeout(() => {
					this.$store.commit('negotiationRfq/SET_SHOW_DEAL_BAN', true)
				}, 300)
			},

			clickFPTN() {
				setTimeout(() => {
					this.$store.commit('negotiationRfq/SET_SHOW_FPTN', true)
				}, 300)
			},
			clickEditBan() {
				setTimeout(() => {
					this.$store.commit('negotiationRfq/SET_SHOW_DEAL_BAN', true)
					this.$store.commit('negotiationRfq/SET_SHOW_EDIT_BAN', true)
				}, 100)
			},
			clickEditFptn() {
				setTimeout(() => {
					this.$store.commit('negotiationRfq/SET_SHOW_FPTN', true)
					this.$store.commit('negotiationRfq/SET_SHOW_EDIT_FPTN', true)
				}, 100)
			},
			changeCollapse() {
				this.collapse.negotiationNote = !this.collapse.negotiationNote
			},
			showPopUpNegotiationNote() {
				this.popUpNegotiationNote = !this.popUpNegotiationNote
			},
			negotiationStatusText(negStatus) {
				switch (negStatus) {
					case 'NONE':
						return 'No Initial Offer'
					case 'INITIAL_OFFER':
						return 'Initial Offer'
					case 'NEGOTIATION':
						return this.negotiationDetail.negotiationStatusLabel
					case 'FINAL_DEAL':
						return 'Final Deal'
				}
			},
			negotiationStatusTextColor(negStatus) {
				switch (negStatus) {
					case 'NONE':
						return 'text-gray-darkest'
					case 'INITIAL_OFFER':
						return 'text-warning-darkest'
					case 'NEGOTIATION':
						return 'text-secondary-darkest'
					case 'FINAL_DEAL':
						return 'text-success'
				}
			},
			negotiationStatusBg(negStatus) {
				switch (negStatus) {
					case 'NONE':
						return 'bg-gray-lightest'
					case 'INITIAL_OFFER':
						return 'bg-warning-lightest'
					case 'NEGOTIATION':
						return 'bg-secondary-lightest'
					case 'FINAL_DEAL':
						return 'bg-success-lightest'
				}
			},
			fptnStatusBg(status) {
				const negStatus = status.toLowerCase()
				switch (negStatus) {
					case 'none':
						return 'bg-gray-lightest'
					case 'waiting approval':
						return 'bg-warning-lightest text-warning-dark'
				}
			},
			backToVendorList() {
				this.vendorDetail = null
			},
			
			async changeNegotiationRequired(e) {
				try {
					this.modal.modalLoading = true
					//set isNegotiationRequired
					this.$store.commit('negotiationRfq/SET_NEGOTIATION_DETAIL', { ...this.negotiationDetail, isNegotiationRequired: e })
					const response = await this.$store.dispatch('negotiationRfq/putNegotiationRequired',{rfqId:this.$route.params.quotationId, isNegotiationRequired:e})
					if (response?.status < 300 && response?.status >= 200) {
						this.modal.modalLoading = false
						this.$store.dispatch('negotiationRfq/getNegotiationList',{rfqId:this.$route.params.quotationId})
						this.isChangeRequired= false
					} else {
						this.$store.dispatch('modal/throwError', { response })
					}
				} catch (error) {
					this.modal.modalLoading = false
					this.modal.modalError = {value:true, title:error.title, message:error.message, traceid:error.traceid}
				}
				
			},
			clickUploadOffer() {
				this.$store.commit('negotiationRfq/SET_MODAL_UPLOAD_OFFER', true)
			},
			clickToggleIsOpenReNegotiation(val) {
				this.isOpenReNegotiation = val
			},
			async clickReNegotiation() {
				try {
					this.modal.modalLoading = true
					const response = await this.$store.dispatch('negotiationRfq/putReNegotiation', { rfqId: this.$route.params.quotationId })
					if (response?.status < 300 && response?.status >= 200) {
						this.modal.modalLoading = false
						this.$store.dispatch('negotiationRfq/getNegotiationList', { rfqId:this.$route.params.quotationId })
					} else {
						this.$store.dispatch('modal/throwError', { response })
					}
				} catch (error) {
					console.log(error);
					this.modal.modalLoading = false
					this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
				}
			},
			logApprovalStatusBg(status) {
				const statusLower = status?.toLowerCase()
				switch (statusLower) {
					case 'waiting approval':
						return 'bg-warning-lightest'
					case 'rejected':
						return 'bg-error-lightest'
					case 'approved': 
						return 'bg-success-lightest'
					default: 
						return ''
				}
			},
			logApprovalStatusText(status) {
				const statusLower = status?.toLowerCase()
				switch (statusLower) {
					case 'waiting approval':
						return 'text-warning-darkest'
					case 'rejected':
						return 'text-error-darkest'
					case 'approved': 
						return 'text-success-darkest'
					default: 
						return ''
				}
			},
			logApprovalStatusIcon(status) {
				const statusLower = status?.toLowerCase()
				switch (statusLower) {
					case 'waiting approval':
						return 'icon-clock text-warning-darkest'
					case 'rejected':
						return 'icon-x text-error-darkest'
					case 'approved':
						return 'icon-check text-success-darkest'
					default: 
						return ''
				}
			},
			clickToggleMaximizeTable() {
				this.isOpenMaximizeTable = !this.isOpenMaximizeTable
			},
		},
		mounted(){
			this.$store.dispatch('negotiationRfq/getNegotiationList', {rfqId:this.$route.params.quotationId})
			this.$store.commit('negotiationRfq/SET_SHOW_VENDOR_DETAILS', true)
		},
		destroyed() {
			this.$store.commit('negotiationRfq/SET_SHOW_VENDOR_DETAILS', false)
			clearInterval(this.counterInterval)
			this.$store.commit('projectLog/SET_COUNTER_INTERVAL', null)
		},
		components: {
			NegotiationTable,
			NegotiationScheduled,
			MaximizeTable,
			NegotiationRequired,
			UploadOffer,
			EditOffer,
			FptnContinueDraft,
			BanContinueDraft,
			BanDealContinueDraft,
			ReNegotiation,
			ModalConfirm
		}
	}
</script>
<style lang="scss" scoped>
	.bg-e-lightest {
		background-color: rgba(255, 238, 242, 1);
	}
</style>