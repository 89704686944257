<template>
	<div class="overflow-auto">
    <div class="relative w-full overflow-auto border rounded-lg min-w-table-mobile max-h-custom border-primary border-spacing-custom">
      <div class="w-full min-width-custom">
        <table class="w-full h-px border-collapse" aria-describedby="projectProcurementApproval">
          <thead class="z-20 h-10 py-1 bg-white">
            <tr class="h-12 border-b text-gray-darkest border-primary">
              <th scope="col" class="border-r border-primary min-w-64 ">
                <h5 class="flex items-center h-10 ml-2 text-sm font-semibold text-left">Vendor Name</h5>
              </th>
              <th scope="col" class="pl-2 border-r border-primary min-w-64">
                <h5 class="flex items-center h-10 text-sm font-semibold text-left">Waiver BG</h5>
              </th>
              <th scope="col" class="pl-2 min-w-64">
                <h5 class="flex items-center h-10 text-sm font-semibold text-left">Grand Total in IDR</h5>
              </th>
            </tr>
          </thead>
          <tbody>
            <template>
              <tr class="h-12 border-primary">
                <td class="pl-2 pr-3 border-r border-primary">
                  <div class="py-2 min-w-60">
                    <h5 class="text-sm font-medium text-gray-darkest">{{ negotiationResult.vendorName }}</h5>
                  </div>
                </td>
                <td class="pl-2 pr-3 border-r border-primary ">
                  <h5 class="text-sm text-gray-darkest">{{ waiverBg }}</h5>
                </td>
                <td class="pl-2 pr-3">
                  <h5 class="text-sm font-bold text-gray-darkest">{{ formatCurrency(negotiationResult.negotiationResultDetails.grandTotalIdr) }}</h5>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
			</div>
		</div>
	</div>
</template>

<script>
import { formatCurrency } from '@/core/components/helpers/formatRupiah'

export default {
	name: 'ProjectProcurementApprovalTable',
  computed: {
    negotiationResult(){
      return this.$store.state.procurementApprovalRfq.dataProcurementApproval.negotiationResult
    },
    waiverBg(){
      return this.$store.state.procurementApprovalRfq.waiverBg
    }
  },
  methods: {
    formatCurrency,
	}
}
</script>
<style scoped>
.border-spacing-custom {
  border-spacing: 0px 0px;
}
.min-width-custom {
	min-width: 40rem;
}
.min-w-60 {
	min-width: 15rem;
}
.min-w-40 {
  min-width: 10rem;
}
.min-w-table-mobile {
  min-width: 25rem;
}
</style>
