<template>
  <div class="w-full">
    <div v-if="!loading">
      <div class="w-full">
        <div @click="changeCollapse('ban')">
          <div class="flex justify-between cursor-pointer py-2.5">
            <div class="flex items-center">
              <span class="mr-3 text-2xl font-bold cursor-pointer icon-more-horizontal text-gray"></span>
              <h6>BAN</h6>
            </div>
            <div>
              <p :class="collapse.ban ? 'rotate-180' : 'rotate-0'"
                class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
            </div>
          </div>
        </div>
        <div v-if="!collapse.ban" class="py-3.5">
          <div v-if="negotiationBan.banList?.length === 0">
            <p class="text-sm font-medium text-center text-gray-light">No Ban yet</p>
          </div>
          <div v-else class="flex flex-wrap gap-y-4" :class="{ 'overflow-auto': quotationDetailContainerWidth > 640}" >
            <div v-for="n,j in negotiationBan.banList" :key="j" class="mr-4 flex flex-col justify-between bg-white border border-gray-lightest rounded-xl shadow-gray-sm p-3.5">
              <div class="w-48">
                <div class="flex justify-center mb-4">
                  <img src="@/core/assets/icons/message/saved.svg" alt="saved" class="w-12">
                </div>
                <div class="pb-4 border-b border-gray-lightest">
                  <p class="text-base font-medium text-center text-gray" >{{ n.title }}</p>
                  <p class="text-base font-medium text-center" :class="{'text-error': n.statusDeal === 'Offer Revision', 'text-success': n.statusDeal === 'Final Deal'}">{{ n.statusDeal }}</p>
                </div>
                <div class="py-4">
                  <div class="text-center">
                    <p class="text-sm font-medium" :class="statusApprovedColor(n.statusApproval)">{{n.statusApproval}}</p>
                    <p class="text-sm font-medium text-gray">{{n.approver}}</p>
                  </div>
                </div>
              </div>
              <div>
                <button-gista 
                    v-if="menuRfqNegotiationRenegotiation && rejected(n) && !sppProcess"
                    color="secondary"
                    size="small"
                    @click="clickTakeAction()"
                    customClass="border border-secondary shadow-secondary-sm w-full font-semibold"
                  >Take Action</button-gista>
                
                <button-gista 
                  v-else-if="!approved(n) && n.approver === userProfile.name && !rejected(n) && menuRfqNegotiationBanApprove && !sppProcess"
                  color="secondary"
                  size="small"
                  customClass="border-secondary bg-secondary shadow-secondary-sm w-full font-semibold"
                  @click="clickViewDetailBan( n.negotiationId, n.statusDeal)"
                >Approve / Reject</button-gista>
                
                <button-gista 
                  v-else-if="menuRfqNegotiationBan"
                  type="tertiary"
                  color="gray"
                  size="small"
                  customClass="border border-primary shadow-primary-sm w-full font-semibold"
                  @click="clickViewDetailBan( n.negotiationId, n.statusDeal)"
                >View BAN</button-gista>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between cursor-pointer py-2.5" @click="changeCollapse('fptn')">
        <div class="flex items-center">
          <span class="mr-3 text-2xl font-bold cursor-pointer icon-more-horizontal text-gray"></span>
          <h6>FPTN</h6>
        </div>
        <div>
          <p :class="collapse.fptn ? 'rotate-180' : 'rotate-0'"
            class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
        </div>
      </div>
      <div v-if="!collapse.fptn" class="py-3.5">
        <div v-if="negotiationBan.fptnList?.length === 0">
          <p class="text-sm font-medium text-center text-gray-light">No FPTN yet</p>
        </div>
        <div v-else class="flex overflow-auto">
          <div v-for="n,j in negotiationBan.fptnList" :key="j" :class="quotationDetailContainerWidth < 640 ? 'flex-col' : 'overflow-auto'" class="mr-4 flex flex-col justify-between bg-white border border-gray-lightest rounded-xl shadow-gray-sm p-3.5">
            <div class="w-48">
              <div class="flex justify-center mb-4">
                <img src="@/core/assets/icons/message/saved.svg" alt="saved" class="w-12">
              </div>
              <div class="pb-4 border-b border-gray-lightest">
                <p class="text-base font-medium text-center text-gray" >{{ n.title }}</p>
                <p class="text-base font-medium text-center" :class="{'text-error': n.statusDeal === 'Offer Revision', 'text-success': n.statusDeal === 'Final Deal'}">{{ n.statusDeal }}</p>
              </div>
              <div class="py-4">
                <div class="text-center">
                  <p class="text-sm font-medium" :class="statusApprovedColor(n.statusApproval)">{{n.statusApproval}}</p>
                  <p class="text-sm font-medium text-gray">{{n.approver}}</p>
                </div>
              </div>
            </div>
            <div>
              <button-gista 
                color="secondary"
                v-if="menuRfqNegotiationRenegotiation && rejected(n) && !sppProcess"
                size="small"
                @click="clickTakeAction()"
                customClass="border border-secondary shadow-secondary-sm w-full font-semibold"
              >Take Action</button-gista>
              <button-gista 
                v-else-if="!approved(n) && n.approver === userProfile.name && !rejected(n) && menuRfqNegotiationFptnApprove && !sppProcess"
                color="secondary"
                size="small"
                customClass="border-secondary bg-secondary shadow-secondary-sm w-full font-semibold"
                @click="clickViewFPTN(n)"
              >Approve / Reject</button-gista>
              <button-gista 
                v-else-if="menuRfqNegotiationFptn"
                type="tertiary"
                color="gray"
                size="small"
                customClass="border border-primary shadow-primary-sm w-full font-semibold"
                @click="clickViewFPTN(n)"
              >View FPTN</button-gista>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="animate-pulse">
        <div class="w-40 h-4 mt-6 mb-4 rounded bg-gray-light"></div>
        <div class="flex flex-wrap gap-y-4">
          <div v-for="n,i in 2" :key="i" class="mr-4 flex flex-col justify-between bg-white border border-gray-lightest rounded-xl shadow-gray-sm p-3.5">
            <div class="flex flex-col items-center w-48">
              <div class="w-16 h-16 mb-4 rounded bg-gray-light"></div>
              <div class="w-32 h-4 mb-2 rounded bg-gray-light"></div>
              <div class="w-32 h-4 mb-10 rounded bg-gray-light"></div>
              <div class="h-8 rounded bg-gray-light w-44"></div>
            </div>
          </div>
        </div>
        <div class="w-40 h-4 mt-6 mb-4 rounded bg-gray-light"></div>
        <div class="flex flex-wrap gap-y-4">
          <div v-for="n,i in 2" :key="i" class="mr-4 flex flex-col justify-between bg-white border border-gray-lightest rounded-xl shadow-gray-sm p-3.5">
            <div class="flex flex-col items-center w-48">
              <div class="w-16 h-16 mb-4 rounded bg-gray-light"></div>
              <div class="w-32 h-4 mb-2 rounded bg-gray-light"></div>
              <div class="w-32 h-4 mb-10 rounded bg-gray-light"></div>
              <div class="h-8 rounded bg-gray-light w-44"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

</template>
<script>
import { QUOTATIONNEGOTIATION } from '@/core/constant/routeName'

  export default {
    name: "Ban",
    data() {
      return {
        
        collapse: {
          ban: false,
          fptn: false,
        },
        loading: false,
      }
    },
    computed: {
      negotiationBan(){
        return this.$store.state.negotiationRfq.negotiationBan
      },
      userProfile() {
        return this.$store.state.dashboard.userProfile
      },
      menuRfqNegotiationRenegotiation() {
				return this.$store.getters['dashboard/menuRfqNegotiationRenegotiation']?.canUpdate
			},
      menuRfqNegotiationFptn() {
        return this.$store.getters['dashboard/menuRfqNegotiationFptn']?.canRead
      },
      menuRfqNegotiationBan() {
        return this.$store.getters['dashboard/menuRfqNegotiationBan']?.canRead
      },
      menuRfqNegotiationBanApprove() {
        return this.$store.getters['dashboard/menuRfqNegotiationBanApprove']?.canUpdate
      },
      menuRfqNegotiationFptnApprove() {
        return this.$store.getters['dashboard/menuRfqNegotiationFptnApprove']?.canUpdate
      },
      quotationDetailContainerWidth() {
        return this.$store.state.width.quotationDetailContainerWidth
      },
      sppProcess() {
        return this.$store.state.sppRfq.sppProcess
      },
    },
    methods: {
      changeCollapse(type) {
        console.log(type);
        this.collapse[type] = !this.collapse[type]
      },
      clickViewFPTN(n) {
        this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_FPTN', true)
        this.$router.push({ name: QUOTATIONNEGOTIATION, query: { tab: 'BAN', fptn: n.negotiationId }})
      },
      rejected(n) {
        const checking = n.statusApproval ? n.statusApproval.includes('Rejected') : false
        return n.isRenegotiation ? false : checking
      },
      statusApprovedColor(status) {
        if (status?.toLowerCase().includes('approved')) {
          return 'text-success'
        } else if (status?.toLowerCase().includes('rejected')) {
          return 'text-error'
        } else {
          return 'text-warning'
        }
      },
      approved(n) {
        return n.statusApproval ? n.statusApproval.includes('Approved') : false
      },
      clickTakeAction() {
        this.$emit('setShowNegotiationTab');
        
      },
      clickViewDetailBan(negotiationId, statusDeal) {
        const STATUS_DEAL = 'Offer Revision'
        this.$store.commit('negotiationRfq/SET_BAN_NEGOTIATION_ID', negotiationId)
        if (statusDeal === STATUS_DEAL) {
          this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_OFFER', true)
          this.$router.push({ name: QUOTATIONNEGOTIATION, query: { tab: 'BAN', detail: 'offer', negotiation: negotiationId }})
        } else {
          this.$store.commit('negotiationRfq/SET_SHOW_DETAIL_BAN', true)
          this.$router.push({ name: QUOTATIONNEGOTIATION, query: { tab: 'BAN', negotiation: negotiationId }})
        }
      },
      
    },
    async mounted(){
      this.loading = true
      const response =  await this.$store.dispatch('negotiationRfq/getNegotiationBan', { quotationId: this.$route.params.quotationId })
      if (response?.status === 200) {
        this.loading = false
      }
    }
  }

</script>