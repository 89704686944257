<template>
  <div>
    <gista-modal
      :value="modalUploadOffer"
      width="11/12"
			scrollable
			@input="toggle"
			customClass="bg-white top-0 bottom-0 mt-6 mb-8 max-w-screen-2xl">
      <ValidationObserver ref="formUploadOffer">
        <div class="flex flex-col justify-between h-full">
          <div>
            <div class="flex justify-center py-4">
              <h2 class="text-xl font-semibold text-gray-dark">Upload Offer</h2>
            </div>
            <div class="flex flex-wrap w-full px-4 pb-6">
              <div class="w-full mb-4 sm:w-1/2 lg:w-1/5 min-w-form sm:pr-6 lg:mb-0">
                <div class="mb-1">
                  <p class="text-sm font-medium text-gray">Cost Type <span class="text-error">*</span></p>
                </div>
                <div class="flex h-12 gap-x-4">
                  <radio-gista :disabled="disabledForm" :modelValue="form.costType === 'FIX_COST'? 'FIX_COST': ''" nativeValue="FIX_COST" @change="changeInputCostType" class="text-gray">Fixed</radio-gista>
                  <radio-gista :disabled="disabledForm" :modelValue="form.costType === 'RATE_COST'? 'RATE_COST': ''" nativeValue="RATE_COST" @change="changeInputCostType" class="text-gray">Rate</radio-gista>
                  <radio-gista :disabled="disabledForm" :modelValue="form.costType === 'MIX_COST'? 'MIX_COST': ''" nativeValue="MIX_COST" @change="changeInputCostType" class="text-gray">Mixed</radio-gista>
                </div>
                <div v-if="error.costType">
                  <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
                </div>
              </div>
              <div class="w-full mb-4 lg:border-r lg:pr-6 border-gray-lightest sm:w-1/2 lg:w-1/5 min-w-form lg:mb-0">
                <div class="mb-1">
                  <p class="text-sm font-medium text-gray">VAT <span class="text-error">*</span></p>
                </div>
                <div class="">
                  <select-gista
                  :options="masterTax"
                  @change="changeVAT($event)"
                  :disabled="disabledForm"
                  :value="form.taxText"
                  :error="error.vat"
                  >
                  <template #selected>
                    {{ form.taxText || 'Select' }}
                  </template>
                  </select-gista>
                  <div v-if="error.vat">
                    <p class="mt-1 text-xs text-error">{{ MESSAGE_EMPTY_FIELD }}</p>
                  </div>
                </div>
              </div>
              <div class="w-full mb-4 sm:w-1/2 lg:w-1/5 lg:pl-6 sm:pr-6 min-w-form lg:mb-0">
                <ValidationProvider
                  ref="fileQuotation"
                >
                  <div class="mb-1">
                    <p class="text-sm font-medium text-gray" :class="errorFileType? 'text-error' : 'text-gray'">File Quotation <span class="text-error">*</span></p>
                  </div>
                  <div>
                    <div class="flex items-center justify-between h-12 border rounded-lg"
                      :class="{ 'border-error': errorFileType || error.fileQuotation, 'border-primary': !errorFileType && !disabledForm && !error.fileQuotation, 'bg-gray-lightest border-none': disabledForm, }">
                      <input v-if="!disabledForm" type="file"
                        id="upload-file-quotation"
                        ref="fileQ"
                        hidden
                        @change="chooseFileQuotation($event)" />
                      <div class="flex justify-between" style="width: calc(100% - 7rem);">
                        <span class="px-4 text-sm truncate text-gray">{{ form.fileQuotation?.name }}</span>
                        <span v-if="!disabledForm && form.fileQuotation?.name" class="pr-2 cursor-pointer icon-trash-2 text-error hover:text-error-dark" @click="removeFileQuotiation"></span>
                      </div>
                      <label for="upload-file-quotation"
                        :class="disabledForm? 'bg-primary-lightest cursor-not-allowed' : 'bg-primary hover:bg-primary-dark cursor-pointer'"
                        class="flex items-center justify-center h-12 p-2 text-white rounded-r-lg w-28">
                        Pilih File
                      </label>
                    </div>
                    <div>
                      <p class="mt-1 text-xs text-tertiary"
                        :class="{ 'text-error': errorFileType || error.fileQuotation }">
                        Supported file: .pdf, max file size: 10 MB
                      </p>
                    </div>
                  </div>
                </ValidationProvider>
              </div>
              <div class="w-full mb-4 sm:w-1/2 lg:w-1/5 lg:pr-6 min-w-form lg:mb-0">
                <ValidationProvider name="Offer No"
                  rules="required|max:128"
                  v-slot="{ errors }">
                  <div class="mb-1">
                    <p class="text-sm font-medium text-gray">Offer No. <span class="text-error">*</span></p>
                  </div>
                  <div>
                    <InputSolutip type="text" size="medium" v-model="form.offerNo" :disabled="disabledForm" :error="!!errors[0]">
                      <template #message>
                        <p>{{ errors[0] }}</p>
                      </template>
                    </InputSolutip>
                  </div>
                </ValidationProvider>
              </div>
              <div class="z-30 w-full mb-4 lg:p-0 sm:w-1/2 lg:w-1/5 min-w-form sm:pr-6 lg:mb-0">
                <ValidationProvider name="Offer Date"
                  rules="required"
                  v-slot="{ errors }">
                  <div class="mb-1">
                    <p class="text-sm font-medium text-gray">Offer Date <span class="text-error">*</span></p>
                  </div>
                  <div>
                    <Datepicker2
                      mode="date"
                      placeholder="dd/mm/yyyy"
                      size="medium"
                      v-model="form.offerDate"
                      data-vv-as="Masa selesai"
                      :disabled="disabledForm"
                      :max-date="new Date()"
                      :error="!!errors[0]"
                    >
                      <template>
                        <p class="mt-1 text-xs text-error">{{ errors[0] }}</p>
                      </template>
                    </Datepicker2>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="border-b border-gray-lightest shadow-primary-lg"></div>
            <NegotiationTableUpload 
              :vat="form.taxText" 
              :enableSaveOffer="enableSaveOffer"
              :inputTotalPrice="inputTotalPrice"
              @input="changeInputTotalPrice"
              @uploadFile="uploadFile" 
              :costType="form.costType" 
              :file="form.file"
              :errorTotalPrice="error.totalPrice"
              @changeErrorTotalPrice="changeErrorTotalPrice"
              @deleteTotalPriceState="deleteTotalPriceState"
              @deleteFile="deleteFile"/>
          </div>
          <div>
            <div class="border-b border-gray-lightest shadow-primary-lg"></div>
            <div class="flex justify-end m-6">
              <button-gista
                @click="clickSaveOffer"
                color="success"
                customClass="sm:w-56 w-full"
                :disabled="disabledSaveOfferButton"
              >Save Offer</button-gista>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </gista-modal>
  </div>
</template>

<script>
import NegotiationTableUpload from '@/core/components/rfq/negotiation/NegotiationTableUpload.vue';
import { MESSAGE_EMPTY_FIELD, MESSAGE_POPUP_VALIDATION_SUBMIT } from '@/core/constant/errorMessage.js'
import Datepicker2 from '@/core/components/custom/DatePicker2.vue'
import InputSolutip from '@/core/components/custom/Input.vue'
export default {
    name: "UploadOffer",
    data() {
        return {
          form: {
            costType: '',
            fileQuotation: {},
            file: {},
            taxId: '',
            taxText: '',
            ppn: 0,
            offerDate: '',
            offerNo: '',
            totalPrice: '',
          },
          FIX_COST: 'FIX_COST',
          RATE_COST: 'RATE_COST',
          MIX_COST: 'MIX_COST',
          inputTotalPrice: '',
          MESSAGE_EMPTY_FIELD: MESSAGE_EMPTY_FIELD,
          error: {
            costType: false,
            fileQuotation: false,
            vat: false,
            totalPrice: false
          },
          errorFileType: false,
          enableSaveOffer: false
        };
    },
    computed: {
      negotiationOfferTableUpload: {
        get() {
          return this.$store.state.negotiationRfq.negotiationOfferTableUpload
        },
        set(val) {
				  this.$store.commit('negotiationRfq/SET_NEGOTIATION_OFFER_TABLE_UPLOAD', val)
        }
      },
      disabledForm(){
        return this.negotiationOfferTableUpload.items.length ===0
      },
      disabledSaveOfferButton() {
        return !this.enableSaveOffer || this.disabledForm || !this.form.costType || !this.form.fileQuotation?.size || !this.form.file?.size || !this.form.taxId || !this.form.offerDate || !this.form.offerNo || (['', 0, '0'].includes(this.form.totalPrice) && this.form.costType === this.MIX_COST)
      },
      modalUploadOffer() {
        return this.$store.state.negotiationRfq.modalUploadOffer
      },
      negotiationDetail(){
				return this.$store.state.negotiationRfq.negotiationDetail
			},
      negotiationOfferLast() {
        return this.$store.state.negotiationRfq.negotiationOfferLast
      },
    
      negotiationOfferTable: {
        get() {
          return this.$store.state.negotiationRfq.negotiationOfferTable
        },
        set(val) {
          this.$store.commit('negotiationRfq/SET_NEGOTIATION_OFFER_TABLE', val)
        }
      },
     
      masterTax() {
        return this.$store.state.negotiation.masterTax.map(e => {
          if (e.ppn === 0) {
            return { ppn: e.ppn, text: e.taxType, taxId: e.taxId }
          }
          return { ppn: e.ppn, text: `${e.taxType} - ${e.ppn}%`, taxId: e.taxId }
        })
      },
      modal: {
        get() {
          return this.$store.state.modal.modal
        },
        set(value) {
          this.$store.commit('modal/SET_MODAL', value)
        }
      },
     

    },
    watch: {
      negotiationOfferTableUpload() {
        this.setNegotiationOfferTableVAT()
      }
    },
    methods: {
        toggle() {
            this.$store.commit('negotiationRfq/SET_MODAL_UPLOAD_OFFER', false)
        },
        changeInputCostType(e) {
          this.form.costType = e
          this.error.costType = false
          this.uploadFile({ file: this.form.file, totalPrice: this.form.totalPrice })
        },
        async uploadFile(e) {
          try {
            const { file, totalPrice } = e
            this.form.file = file
            this.form.totalPrice = totalPrice
            this.modal.modalLoading = true
            //hash file checksum md5
            const encrypted = await this.$store.dispatch('file/hashMD5', file)
            const formData = new FormData()
            formData.append('file', file)
				    formData.append('rfqId', this.$route.params.quotationId)
            formData.append('checksum', encrypted)
            formData.append('costType', this.form.costType ?? this.FIX_COST)
            formData.append('totalPrice', totalPrice.split(',').join(''))
            const response = await this.$store.dispatch('negotiationRfq/postOfferUpload', formData)
            if (response?.status < 300 && response?.status >= 200) {
              this.$store.commit('negotiationRfq/SET_NEGOTIATION_OFFER_TABLE_UPLOAD', response.data.result)
              this.modal.modalLoading = false
              this.enableSaveOffer = response.data.result.enableSaveOffer
            } else {
              this.$store.dispatch('modal/throwError', { response })
            }
          } catch (error) {
            //loading out when error
            this.modal.modalLoading = false;
            this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message ?? error?.currentTarget?.error?.message}
          }
        },
        changeVAT(e) {
          this.form.taxId = e.taxId.toString()
          this.form.ppn = e.ppn
          this.form.taxText = e.text
          this.error.vat = false
          this.setNegotiationOfferTableVAT()
        },
        setNegotiationOfferTableVAT() {
          this.negotiationOfferTableUpload.ppn = this.form.ppn
          this.negotiationOfferTableUpload.ppnPrice = this.negotiationOfferTableUpload.totalPrice * this.form.ppn/100
          this.negotiationOfferTableUpload.finalTotal = this.negotiationOfferTableUpload.totalPrice + (this.negotiationOfferTableUpload.totalPrice * this.form.ppn/100)
        },
        async chooseFileQuotation(e) {
          const file = e.target?.files?.[0]
			    const checkFileValidation = await this.$store.dispatch('file/checkFileValidation', { file, fileType: ['application/pdf'] })
          if(checkFileValidation){
            if(!this.form.fileQuotation?.name) this.errorFileType = true
            if (this.$refs['fileQ']) {
              this.$refs['fileQ'].value = ''
            }
            return
          }
          this.form.fileQuotation = file
          this.error.fileQuotation = false
          this.errorFileType = false
        },
        removeFileQuotiation() {
          if (this.$refs['fileQ']) {
            this.$refs['fileQ'].value = ''
          }
          this.form.fileQuotation = {}
        },
        resetState() {
          this.form = {
            costType: '',
            fileQuotation: {},
            file: {},
            taxId: '',
            taxText: '',
            offerDate: '',
            offerNo: '',
            totalPrice: '',
          }
          const negotiationOfferTableReset = {
            items: [],
            finalTotal: null,
            ppn: null,
            ppnPrice: null,
            totalPrice: null,
          }
          this.$store.commit('negotiationRfq/SET_NEGOTIATION_OFFER_TABLE', negotiationOfferTableReset)
          this.$store.commit('negotiationRfq/SET_NEGOTIATION_OFFER_TABLE_UPLOAD', negotiationOfferTableReset)
        },
        checkErrorValidate() {
          if (!this.form.costType) {
            this.error.costType = true
          }
          if (!this.form.taxId) {
            this.error.vat = true
          }
          if (!this.form.fileQuotation.name) {
            this.error.fileQuotation = true
          }
          if (['', 0, '0'].includes(this.form.totalPrice) && this.form.costType === this.MIX_COST) {
            this.error.totalPrice = true
          }
          return Object.values(this.error).every(e => !e)
        },
        async clickSaveOffer() {
          try {
            this.modal.modalLoading = true
            const successValidate = await this.$refs.formUploadOffer.validate()
            const checkErrorValidate = this.checkErrorValidate()
            if (!successValidate || !checkErrorValidate) {
              this.modal.modalLoading = false
              this.modal.modalErrorValidate = { value: true, message: MESSAGE_POPUP_VALIDATION_SUBMIT }
              return
            }
            //hash file checksum md5
            const encryptedFile = await this.$store.dispatch('file/hashMD5', this.form.file)
            const encryptedFileQuotation = await this.$store.dispatch('file/hashMD5', this.form.fileQuotation)
            const formData = new FormData()
            formData.append('costType', this.form.costType)
            formData.append('taxId', this.form.taxId)
            formData.append('fileQuotation', this.form.fileQuotation)
            formData.append('fileQuotationChecksum', encryptedFileQuotation)
            formData.append('file', this.form.file)
            formData.append('checksum', encryptedFile)
            formData.append('offerDate', this.form.offerDate)
            formData.append('offerNo', this.form.offerNo)
            formData.append('rfqId', this.$route.params.quotationId)
            formData.append('totalPrice', this.form.totalPrice)
            const response = await this.$store.dispatch('negotiationRfq/postSaveOffer', formData)
            if (response?.status < 300 && response?.status >= 200) {
              this.modal.modalLoading = false
              setTimeout(() => {
                this.$store.commit('negotiationRfq/SET_MODAL_UPLOAD_OFFER', false)
                this.$store.dispatch('negotiationRfq/getNegotiationList',{rfqId:this.$route.params.quotationId})
                this.resetState()
              }, 200)
            } else {
              this.$store.dispatch('modal/throwError', { response })
            }
          } catch (error) {
            this.modal.modalLoading = false
            console.log(error);
            this.modal.modalError = { value: true, traceid: error.traceid, title: error.title, message: error.message }
          }
        },
        changeInputTotalPrice(e) {
          this.inputTotalPrice = e
        },
        changeErrorTotalPrice(e) {
          this.error.totalPrice = e
        },
        deleteTotalPriceState() {
          this.form.totalPrice = ''
        },
        deleteFile() {
          this.form.file = {}
        }
    },
    async mounted(){
      this.$store.dispatch('negotiation/getNegotiationMasterTax')
      const NO_INITIAL_OFFER_STATUS = 'No Initial Offer'
      if (this.negotiationDetail.negotiationStatus && this.negotiationDetail.negotiationStatus !== NO_INITIAL_OFFER_STATUS) {
        await this.$store.dispatch('negotiationRfq/getNegotiationOfferLast',{ rfqId:  this.$route.params.quotationId })
        this.form.costType = this.negotiationOfferLast.costType
        if (this.negotiationOfferLast.tax?.taxId) {
          this.form.ppn = this.negotiationOfferLast.tax?.ppn
          this.form.taxId = this.negotiationOfferLast.tax?.taxId
          const taxText = this.negotiationOfferLast.tax?.ppn === 0 ? this.negotiationOfferLast.tax?.taxType : `${this.negotiationOfferLast.tax?.taxType} - ${this.negotiationOfferLast.tax?.ppn}%` 
          this.form.taxText = taxText
        }
      } else {
        this.form.costType = this.FIX_COST
      }
    },
    destroyed() {
      this.resetState()
      
    },
    components: { 
      NegotiationTableUpload,
      Datepicker2,
      InputSolutip
    }
}
</script>

<style scoped>
.min-w-form {
  min-width: 17rem;
}
</style>