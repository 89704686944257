<template>
  <div class="my-6">
    <div class="flex justify-between mb-6" :class="quotationDetailContainerWidth < 640 ? 'flex-col' : 'items-center flex-row'">
      <h1 class="text-xl font-medium">LOI</h1>
    </div>
    <ApprovalTable/>
    <Information @changeCollapse="changeCollapse" :collapse="collapse.information"/>
    <div class="flex justify-between pt-6 border-t border-gray-lightest my-6">
      <div class="flex items-center">
        <span class="mr-4 text-lg icon-file-text text-gray"></span>
        <h5 class="text-lg font-medium text-gray-darkest">LOI</h5>
      </div>
      <div class="flex items-center">
        <p :class="!collapse.loi ? 'rotate-180' : 'rotate-0'" @click="changeCollapse('loi')"
          class="text-2xl transition ease-in transform cursor-pointer icon-chevron-down text-gray"></p>
      </div>
    </div>
    <CardLoi v-if="!collapse.loi"/>
    <div class="w-full pt-6 border-t border-gray-lightest my-6">
      <div class="flex cursor-pointer"
        @click="changeCollapse('attachments')">
        <div class="flex flex-auto items-center">
          <span class="mr-4 icon-list"></span>
          <h4 class="text-lg font-medium text-gray-darkest">ATTACHMENTS</h4>
        </div>
        <div class="flex-none">
          <p :class="!collapse.attachments? 'rotate-180': 'rotate-0'"
            class="icon-chevron-down text-gray text-2xl transform transition ease-in"></p>
        </div>
      </div>
    </div>
    <Attachment v-if="!collapse.attachments" :readOnly="true"></Attachment>
  </div>
</template>

<script>
import ApprovalTable from '@/core/components/rfq/loi/ApprovalTable.vue'
import Attachment from '@/core/components/rfq/loi/Attachment.vue'
import Information from '@/core/components/rfq/loi/Information.vue'
import CardLoi from '@/core/components/rfq/loi/CardLoi.vue'

export default {
  name: 'LoiDetails',
  data() {
    return {
      collapse: {
        information: false,
        loi: false,
        attachments: false,
      },
    }
  },
  computed: {
    quotationDetailContainerWidth() {
      return this.$store.state.width.quotationDetailContainerWidth
    },
    draftLoi: {
      get() {
        return this.$store.state.loiRfq.draftLoi
      },
      set(value) {
        this.$store.commit('loiRfq/SET_DRAFT_SKT_LOI', value)
      }
    },
  },
  methods: {
    changeCollapse(key) {
      this.collapse[key] = !this.collapse[key] 
    }
  },
  components: {
    ApprovalTable,
    Attachment,
    Information,
    CardLoi,
  }
}
</script>